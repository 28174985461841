import React from 'react';
import { DataGrid as MDataGrid, GridOverlay } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Button, ButtonGroup, IconButton} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import Title from './title';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';


const useStyles = makeStyles({
    root: {
        display: 'flex',
        // height: '100%',
        borderRadius: 4,
        backgroundColor: 'white',
        width: '100%',
        '& .MuiDataGrid-root': {
            border: 'none'
        },
        // '& .MuiDataGrid-columnsContainer': {
        //     backgroundColor: '#083950',

        //     '& .grid-header': {
        //         color: 'white',
        //         '& .MuiDataGrid-columnHeaderTitle': {
        //             fontWeight: 700,
        //         },
        //         '& .MuiDataGrid-columnSeparator': {
        //             display: 'none',
        //         },
        //     },
        //     '& .Mui-odd': {
        //         backgroundColor: '#f0f0f0'
        //     },
        // },

    },
    content: {
        flexGrow: 1,
        // width: '100%'
    },
});


const CustomNoRowsOverlay = () => (
    <GridOverlay>
        <strong>Sem registros.</strong>
    </GridOverlay>
)


const CustomLoadingOverlay = () => (
    <GridOverlay>
        {/* <div style={{ position: 'absolute', top: 0, width: '100%' }}>
            <LinearProgress />
        </div> */}
        <strong style={{ color: '#252674' }}>Carregando...</strong>
    </GridOverlay>
)


export default function DataGrid(props) {
    const classes = useStyles();
    const columns = [...props.columns.map((e) => ({ ...e, sortable: false }))];
    const Filtros = props.filtros;
    if (props.formfields) {
        props.formfields.forEach(element => {
            if (['checkbox', 'iosswitch', 'money', 'autocomplete'].includes(element.type)) {
                columns.forEach(el => {
                    if (el.field === element.name) {
                        if (!el.renderCell) {
                            if (['checkbox', 'iosswitch'].includes(element.type))
                                el['renderCell'] = (params) => (
                                    params.value ? <RadioButtonCheckedIcon style={{ color: 'green' }} /> : <RadioButtonUncheckedIcon style={{ color: 'red' }} />
                                )
                            else if (element.type === 'money')
                                el['renderCell'] = (params) => {
                                    return params.value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(params.value) : ''
                            }
                            else if (element.type === 'autocomplete')
                                el['renderCell'] = (params) => (
                                    params.value ? params.value.label : ''
                                )
                        }
                    }
                })
            }
        });
    }

    if (!!(props.onDelete && props.has_delete) || !!(props.onUpdate && props.has_delete)) {
        columns.push({
            field: '',
            width: 125,
            headerName: 'Ações',
            headerAlign: 'center',
            backgroundColor: 'white',
            sortable: false,
            headerClassName: 'grid-header',
            renderCell: (params) => {
                return (
                    <Box mx="auto">
                        {!!props.onUpdate && props.has_change && (
                            <IconButton onClick={() => props.onUpdate(params)} size="small" color="primary">
                                <EditOutlinedIcon  />
                            </IconButton>
                        )}
                        {!!props.onDelete && props.has_delete && (
                            <IconButton onClick={() => props.onDelete(params)} size="small" style={{ marginLeft: '5px' }}>
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        )}

                    </Box>
                )
            }
        });
    }

    return (
        <>
            {(props.title || props.action) && (
                <Title
                    action={props.action}
                    tempo_total={props.tempo_total}
                    icon={props.icon}
                    title={props.title}
                    subtitle={props.subtitle}
                />
            )}
            <Box px={2} mb={2}>
                <Box mb={2} textAlign="end">
                    {props.onAdd && props.has_add && (
                        <Button startIcon={<AddIcon />} variant="contained" onClick={() => props.onAdd()} color="secondary">
                            Adicionar
                        </Button>
                    )}
                    {props.extra_buttons.map((el) => 
                        (el)
                    )}
                </Box>
                <div className={classes.root}>
                    <div className={classes.content}>
                        {!!props.filtros && (
                            <Paper elevation={0}>
                                <Box p={2}>
                                    <Filtros />
                                </Box>
                            </Paper>
                        )}
                        <MDataGrid
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                                LoadingOverlay: CustomLoadingOverlay
                            }}
                            rows={props.rows}
                            columns={columns}
                            disableColumnMenu
                            autoHeight
                            hideFooterRowCount
                            hideFooterSelectedRowCount
                            disableColumnSelector
                            disableSelectionOnClick
                            rowHeight={props.rowHeight || 50}
                            paginationMode="server"
                            onPageChange={props.onPageChange}
                            pageSize={props.pageSize || 30}
                            rowCount={props.rowCount || 30}
                            loading={props.loading}
                            hideFooter
                        />
                        {(props.pagination.prev || props.pagination.next) && (
                            <Paper elevation={0}>
                                <Box px={1} py={1} textAlign="end">
                                    <ButtonGroup variant="outlined" color="default">
                                        {props.pagination.prev &&
                                            <Button startIcon={<ChevronLeftIcon />} size="small" onClick={props.handlePrev}>
                                                Anterior
                                            </Button>}


                                        {props.pagination.next &&
                                            <Button endIcon={<ChevronRightIcon />} size="small" onClick={props.handleNext}>
                                                Próximo
                                            </Button>
                                        }
                                    </ButtonGroup>
                                </Box>
                            </Paper>
                        )}
                    </div>
                </div>

            </Box>
        </>
    )
}
