import { BaseAPIClass } from './api';


class PesquisaPrecoAPI extends BaseAPIClass{
    constructor() {
        super('/pesquisa_preco/pesquisa_preco/');
      }     
}


export default new PesquisaPrecoAPI();
