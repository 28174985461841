import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import MAutocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
const {matchSorter} = require('match-sorter')


export default function Autocomplete(props) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const isFetching = open === true;

    useEffect(() => {
        let active = true;
    
        if (!isFetching) {
          return undefined;
        }
    
        (async () => {
            // foward
            setLoading(true);
            let dfoward = {};  
            let options = [];     
            if(props.api){     
                if(typeof(props.getValues) !== 'undefined'){
                    const l = props.forward || [];
                    l.forEach(f => {         
                        const v = props.getValues(f);
                        dfoward[f] = v ? v.id : null;
                    });                   
                }
                const response = await props.api.getSelectOptions(dfoward);
                options = response.data;
              }else{
                options = props.options;
              }
          if (active) {
            setOptions(options);
          }
          setLoading(false);
        })();
    
        return () => {
          active = false;
        };
    }, [isFetching]);
    
    const filterOptions = (options, { inputValue }) => {
      return matchSorter(options, inputValue, {
        keys: ['label', {threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'id'}],
      })
    }

    return (
        <MAutocomplete
            id={props.id}
            options={options}
            multiple={props.multiple || false}
            getOptionLabel={props.optionLabel}
            getOptionSelected={(option, value) => {
                 return option.id === value.id
            } }
            open={open}
            loading={loading}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}            
            filterOptions={filterOptions}
            style={props.style}
            renderOption={props.renderOption}
            disableCloseOnSelect={props.disableCloseOnSelect || false}
            limitTags={props.limitTags}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant={props.variant || 'outlined'}
                    size={props.size || 'small'}
                    fullWidth={props.fullWidth}
                    value={props.inputValue}
                    error={props.error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                    }}

                />
            )}
            fullWidth={props.fullWidth}
            classes={props.classes}
            onChange={props.onChange}
            value={props.value}
            error={props.error}
            name={props.name}
            noOptionsText={'Nenhum registro'}
            loadingText={'Carregando...'}
            disabled={props.disabled}
        />
    );
}
