import React from 'react';
import { Grid } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    CrudsEditTextBox, 
    CrudsAutoComplete,
    CrudsCheckBox,
    CrudsIOSSwitch,
    CrudsMoney,
    CrudsDecimal,
    CrudsDateMask,
    CrudsMask    
} from './inputs';


const CrudsInline = (props) => {
    const {inline, 
        inlineOrder, 
        control, 
        errors, 
        setValue, 
        selectOptions, 
        getSelectOptions, 
        watch, 
        unregister,
        register,
        setInlineCounter,
        inlineCounters,
        inlinIndexes,
        setInlineIndexes,
        getValues} = props;

    const indexes = inlinIndexes[inline.name];

    const addRow = () => {
        const counter = inlineCounters[inline.name];
        indexes.set(counter, counter);
        setInlineIndexes(prev => ({ ...prev, ...inlinIndexes }));
        const d = {}
        d[inline.name] = counter + 1;
        setInlineCounter(prev => ({ ...prev, ...d }));
    };

    const removeRow = index => () => {
        inline.fields.forEach((item) => {
            const name = `${inline.name}_${index}_${item.name}`;
            unregister(name, { keepDefaultValue: true, keepValue: false });
        });
        unregister(`${inline.name}_${index}_inlineIndex`);
        unregister(`${inline.name}_${index}_id`);
        indexes.delete(index);
        setInlineIndexes(prev => ({ ...prev, ...inlinIndexes }));
    };

    return(
        <>
            <Grid key={inlineOrder} container justifyContent="space-between" alignItems="center" >   
            {[...indexes.keys()].map((index, sequence) => {
                return(
                    <Grid key={index} container justifyContent="space-between" alignItems="center" style={{backgroundColor: (sequence % 2 === 0?'#eaeaea':'#ffffff')}}> 
                        <Grid item xs={11}>
                            <Grid container alignItems="center"> 
                                <input
                                    type="hidden"
                                    defaultValue={null}
                                    {...register(`${inline.name}_${index}_id`)} 
                                />                            
                                <input
                                    type="hidden"
                                    defaultValue={index}
                                    {...register(`${inline.name}_${index}_inlineIndex`, { required: true })} 
                                />                                                                                     
                                {inline.fields.map((item, idx) => {
                                    let component;
                                    switch(item.type){
                                        case 'textbox':
                                            component = <CrudsEditTextBox 
                                                            formset={inline.name}
                                                            item={item} 
                                                            idx={idx}
                                                            control={control}
                                                            errors={errors}  
                                                            setValue={setValue} 
                                                            inlineIndex={index}
                                                            fieldWrapperVisible={props.fieldWrapperVisible}
                                                            setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                                        />
                                            break;
                                        case 'autocomplete':
                                            component = <CrudsAutoComplete 
                                                            formset={inline.name}
                                                            item={item} 
                                                            idx={idx}
                                                            selectOptions={selectOptions} 
                                                            control={control}
                                                            errors={errors}  
                                                            getValues={getValues}
                                                            getSelectOptions={getSelectOptions}
                                                            inlineIndex={index}
                                                            setValue={props.setValue}
                                                            fieldWrapperVisible={props.fieldWrapperVisible}
                                                            setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                                        />
                                            break; 
                                        case 'checkbox':
                                            component = <CrudsCheckBox 
                                                            formset={inline.name}
                                                            item={item} 
                                                            idx={idx}
                                                            control={control}
                                                            watch={watch}
                                                            inlineIndex={index}
                                                            fieldWrapperVisible={props.fieldWrapperVisible}
                                                            setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                                            />
                                            break; 
                                        case 'iosswitch':
                                            component = <CrudsIOSSwitch 
                                                            formset={inline.name}
                                                            item={item} 
                                                            idx={idx}
                                                            control={control}
                                                            watch={watch}
                                                            inlineIndex={index}
                                                            fieldWrapperVisible={props.fieldWrapperVisible}
                                                            setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                                            />
                                            break;  
                                        case 'money':
                                            component = <CrudsMoney 
                                                            formset={inline.name}
                                                            item={item} 
                                                            idx={idx}
                                                            control={control}
                                                            errors={errors}
                                                            inlineIndex={index}
                                                            fieldWrapperVisible={props.fieldWrapperVisible}
                                                            setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                                            />
                                            break; 
                                        case 'decimal':
                                            component = <CrudsDecimal
                                                            formset={inline.name}
                                                            item={item} 
                                                            idx={idx}
                                                            control={control}
                                                            errors={errors}
                                                            inlineIndex={index}
                                                            fieldWrapperVisible={props.fieldWrapperVisible}
                                                            setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                                            />
                                            break;
                                        case 'datemask':
                                            component = <CrudsDateMask
                                                            formset={inline.name}
                                                            item={item} 
                                                            idx={idx}
                                                            control={control}
                                                            errors={errors}
                                                            inlineIndex={index}
                                                            fieldWrapperVisible={props.fieldWrapperVisible}
                                                            setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                                            />
                                            break; 
                                        case 'mask':
                                            component = <CrudsMask
                                                            formset={inline.name}
                                                            item={item} 
                                                            idx={idx}
                                                            control={control}
                                                            errors={errors}
                                                            inlineIndex={index}
                                                            fieldWrapperVisible={props.fieldWrapperVisible}
                                                            setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                                            />
                                            break;                                 
                                        default:
                                            component = <span>Invalid</span>;                                
                                    }
                                    return component;
                                })}    
                            </Grid>                        
                        </Grid>
                        <Grid item xs={1} justifyContent="flex-end" style={{display:'flex'}}>
                            <Fab size="small" aria-label="delete" onClick={removeRow(index)} style={{ transform: 'scale(0.6)' }}>
                                <DeleteIcon/>
                            </Fab>                           
                        </Grid>
                    </Grid>
                )
            })}
            </Grid>        
            <Fab size="small" color="primary" aria-label="add" onClick={addRow} style={{float:'right'}}>
                <AddIcon />
            </Fab>                     

        </>
    );    
};


export default CrudsInline;