import React from 'react';
import { Typography, Box, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import SendIcon from '@material-ui/icons/Send';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(1, 2),
        marginBottom: theme.spacing(3)
    },
    title: {
        color: '#fff',
        marginTop: '-5px'
    },
    subtitle: {
        color: '#fff',
    }
}));


export default function Title(props) {
    const { action, title, subtitle, icon } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid container item alignItems="center" xs={12} md={4} >
                    {!icon ? <LayersOutlinedIcon style={{ color: '#fff' }} /> : <span style={{color: '#fff'}}>{icon}</span>}

                    <Box ml={2}>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            {subtitle}
                        </Typography>
                        <Typography variant="h6" className={classes.title}>
                            <strong>{title}</strong>
                        </Typography>
                    </Box>

                </Grid>
                {(!!action) && (
                    <Grid container item alignItems="center" justifyContent="flex-end" xs={12} md={8}>
                        {!!action && (
                            <Grid container item xs={6} md={2} justifyContent="flex-end">
                                <Button endIcon={<SendIcon />} color="gray" size="small" onClick={action} >
                                    Exportar
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}