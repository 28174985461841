import React, {useState} from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { TextField, PasswordField, NonFieldErrorMessage, ErrorMessage } from '@components/atoms';
import { AuthHeader } from '@components/molecules';
import { useForm } from 'react-hook-form';
import UsuarioAPI from '@services/usuario';
import urls from '@urls';
import { Helmet } from 'react-helmet';


export default function CadastroPage({ history }) {
    const { control, handleSubmit, setError, watch, formState: { errors } } = useForm();
    const [nonFieldErrorMessage, setNonFieldErrorMessage] = useState([]);  

    const password = watch('password', '');
    const password2 = watch('password2', '');
    const checkPassword = password === password2;

    const onSubmit = async (data) => {
        try {
            delete data['password2'];
            const response = await UsuarioAPI.register(data);
            if (response.status === 201) {
                history.push(urls.acesso.cadastro.finalizado);
            }
        } catch (error) {
            if (error.response.data.non_field_errors) {
                setNonFieldErrorMessage(error.response.data.non_field_errors);
            }else{
                for (const [key, value] of Object.entries(error.response.data)) {
                    setError(key, {
                        type: 'manual',
                        message: value,
                    });
                }                
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Cadastro - Pesquisa Preço</title>
            </Helmet>
            <AuthHeader title="Cadastro" />
            <Box mt={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                control={control}
                            />
                            <ErrorMessage error={errors['email']} />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <TextField
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                                id="first_name"
                                label="Nome"
                                name="first_name"
                                autoComplete="first_name"                                
                                control={control}
                            />
                            <ErrorMessage error={errors['first_name']} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                                name="last_name"
                                label="Sobrenome"
                                id="last_name"
                                autoComplete="last_name"
                                control={control}

                            />
                            <ErrorMessage error={errors['last_name']} />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <PasswordField
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                id="senha"
                                autoComplete="current-password"
                                control={control}
                            />
                            <ErrorMessage error={errors['password']} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <PasswordField
                                variant="outlined"
                                margin="none"
                                required
                                fullWidth
                                name="password2"
                                label="Confirme sua senha"
                                id="senha2"
                                autoComplete="current-password"
                                control={control}
                                helperText={!!password2 && !checkPassword ? 'As senhas não conferem' : ''}
                                error={!!password2 && !checkPassword}
                            />
                            <ErrorMessage error={errors['password2']} />
                        </Grid>
                    </Grid>
                    <NonFieldErrorMessage non_field_errors={nonFieldErrorMessage} />                      
                    <Box mt={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button type="submit" color="primary" variant="contained" fullWidth>
                                    Enviar
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="button" color="secondary" variant="outlined" fullWidth onClick={(e) => { e.preventDefault(); history.push(urls.acesso.login); }}>
                                    Voltar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                </form>
            </Box>

        </>
    )
}