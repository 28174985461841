import api, { BaseAPIClass } from './api';


class ProdutoAPI extends BaseAPIClass{
    constructor() {
        super('/pesquisa_preco/dashboard/');
      }  

}


export default new ProdutoAPI();
