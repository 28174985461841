import React, {useState} from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Input, InputLabel } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    wrapper: {
        width:'80px',
        height:'80px',
        border:'dashed 1px #cacaca', 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center'
    },
    img:{
        width:'80px',
        height:'80px',      
    }
}));


const ImageUpload = props => {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState(props.value);
    const id =`id${props.id}`;
    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    
    const handleCapture = ({ target }) => {
        getBase64(target.files[0], (value) => {
            setSelectedFile(value);
            if(props.onChange)
                props.onChange(value);
        });        
    };    

  return (
    <div className={classes.wrapper}>
        <InputLabel htmlFor={id} style={{cursor:'pointer'}}>
            <Input
                id={id}
                inputProps={{
                    accept:
                        "image/jpeg, image/png",
                }}
                onChange={handleCapture}
                style={{ display: "none" }}
                type="file"
            />
            
            {selectedFile ? <img src={selectedFile} alt="avatar" className={classes.img} /> : <AddIcon/>}
        </InputLabel>
    </div>      

  )
};

export default ImageUpload;
