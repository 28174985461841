import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { MasterPage, UnauthorizedPage, NotFoundPage } from '@pages/global';
import { useAuth } from '@context/auth';
import { Spinner } from '@components/atoms';
import { hasPermissions } from '@services/utils';
import urls from '@urls';
import * as auth from '@pages/auth';
import * as app from '@pages/app';


function PrivateRoute({ component: Component, ...rest }) {
    const { isAuthenticated, loading, permissions, user } = useAuth();
    const [hasPerm, setHasPerm] = useState(true);

    useEffect(() => {
        function checkPermission() {
            if (isAuthenticated) {
                if(permissions && rest.app && rest.model){
                    setHasPerm(hasPermissions(permissions, rest.app, rest.model));
                } 
            }
        }
        checkPermission();
    }, [permissions]);

    if (loading) {
        return <Spinner open />
    }

    return (
        <Route {...rest} render={props => {
            if (isAuthenticated) {
                if(user && user.is_vendedor && props.match.path === urls.index ){
                    return <Redirect
                    to={{
                        pathname: urls.pesquisapreco.pesquisapreco,
                    }}
                />                    

                }else{
                    if (hasPerm) {
                        return <Component {...props} />
                    } else {
                        return <Redirect
                            to={{
                                pathname: urls.unauthorized,
                                state: { from: props.location }
                            }}
                        />
                    }
                }

            } else {
                return (
                    <Redirect
                        to={{
                            pathname: urls.acesso.login,
                            state: { from: props.location }
                        }}
                    />
                );
            }

        }}
        />
    );
}


export default function Routes() {
    return (
        <Router>
            <MasterPage>
                <Switch>
                    <PrivateRoute exact path={urls.index} component={app.HomePage} />
                   
                    <PrivateRoute exact path={urls.produtos.produto} component={app.ProdutoPage} app={'produtos'} model={'produto'} />
                    <PrivateRoute exact path={urls.produtos.unidademedida} component={app.UnidadeMedidaPage} app={'produtos'} model={'unidademedida'} />
                    <PrivateRoute exact path={urls.impostos.imposto} component={app.ImpostoPage} app={'impostos'} model={'imposto'}/>
                    <PrivateRoute exact path={urls.impostos.aliquota} component={app.AliquotaPage} app={'impostos'} model={'aliquotaicms'}/>
                    <PrivateRoute exact path={urls.entidades.concorrente} component={app.ConcorrentePage} app={'entidades'} model={'concorrente'}/>
                    <PrivateRoute exact path={urls.pesquisapreco.pesquisapreco} component={app.PesquisaPrecoPage} app={'pesquisa_preco'} model={'pesquisapreco'}/>
                    <PrivateRoute exact path={urls.pesquisapreco.historico} component={app.ItensPage} app={'pesquisa_preco'} model={'itens'}/>
                    <PrivateRoute exact path={urls.produtos.listapreco} component={app.ListaPrecoPage} app={'produtos'} model={'listapreco'} />
                    <PrivateRoute exact path={urls.usuarios.usuario} component={app.UsuarioPage} app={'contas'} model={'usuario'} />                 
                    <PrivateRoute exact path={urls.usuarios.pendentes} component={app.UsuarioPendentePage} app={'contas'} model={'usuario'} />                 
                    <PrivateRoute exact path={urls.acesso.alterarsenha} component={auth.AlterarPage} />
                    <Route exact path={urls.acesso.login} component={auth.LoginPage} />
                    <Route exact path={urls.acesso.cadastro.index} component={auth.CadastroPage} />
                    <Route exact path={urls.acesso.cadastro.finalizado} component={auth.FinalizadoPage} />
                    <Route exact path={urls.acesso.esqueci} component={auth.EsqueciMinhaSenhaPage} />
                    <Route exact path={urls.acesso.novasenha} component={auth.NovaSenhaPage} />
                    <Route exact path={urls.unauthorized} component={UnauthorizedPage} />
                    <Route component={NotFoundPage} />
                </Switch>
            </MasterPage>
        </Router>
    )
}