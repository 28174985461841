import React from 'react';
import API from '@services/itens';
import ProdutoAPI from '@services/produto';
import { CrudPage } from '@components/organisms';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';


export default function ItensPage() {
    return (
        <>
            <CrudPage
                api={API}
                title={'Pesquisa de Preços'}
                subtitle={'Histórico'}
                app={'pesquisa_preco'}
                model={'itens'}
                can_add={false}
                export={true}
                exportfilename={'relatorio.xlsx'}
                listColumns={
                    [
                        {
                            field: 'lote',
                            headerName: 'Nro',
                            width:60
                        },
                        {
                            field: 'data',
                            headerName: 'Data',
                            width:100,                      
                        }, 
                        {
                            field: 'produto',
                            headerName: 'Produto',
                            flex: 2
                        },
                        {
                            field: 'estado',
                            headerName: 'Estado',
                            width:80
                        },                        
                        {
                            field: 'concorrente',
                            headerName: 'Concorrente',
                            flex: 1
                        },                                                           
                        {
                            field: 'preco',
                            headerName: 'Preço',
                            flex: 1,
                            renderCell:(params) => {
                                return params.value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(params.value) : ''
                            }                                                       
                        }
                    ]
                }
                fields={[ 
                    {
                        type:'autocomplete',
                        name:'produto', 
                        label: 'Produto',
                        required: true,
                        api: ProdutoAPI,
                    },
                    {
                        type:'money',
                        name:'preco', 
                        label: 'Preço/Metro',
                        required: true,
                    },   
                    {
                        type: 'decimal',
                        name: 'ipi',
                        label: 'IPI',
                        required: true
                    },
                    {
                        type: 'decimal',
                        name: 'pis_cofins',
                        label: 'PIS/COFINS',
                        required: true
                    },                     
                ]}
                search={
                    [
                        {
                            type:'textbox',
                            inputType: 'number',
                            name: 'lote', 
                            label:'Nro',
                            sm:1
                        },                        
                        {
                            type:'textbox',
                            inputType: 'number',
                            name: 'ano', 
                            label:'Ano',
                            sm:1
                        },
                        {
                            type:'textbox',
                            inputType: 'number',
                            name: 'mes', 
                            label:'Mês',
                            sm:1
                        },                    
                        {
                            type:'datepicker',
                            name:'data', 
                            sm:2
                        },                                          
                        {
                            type:'textbox',
                            name: 'descricao', 
                            label:'Pesquise'
                        }
                    ]
                }
                pageIcon={<ChangeHistoryIcon />}
                />
        </>
    );
}