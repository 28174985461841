import React, { useState } from 'react';
import { Title } from '@components/molecules';
import { Spinner, NonFieldErrorMessage, ErrorMessage } from '@components/atoms';
import Button from '@material-ui/core/Button';
import { Toast } from '@components/molecules';
import { Paper, Box, Container, TextField, Divider } from '@material-ui/core';
import UsuarioAPI from '@services/usuario';
import SaveIcon from '@material-ui/icons/Save';
import { Helmet } from 'react-helmet';
import LockIcon from '@material-ui/icons/Lock';
import { useForm } from 'react-hook-form';
import { Controller } from "react-hook-form";


export default function AlterarSenhaPage() {
    const { handleSubmit, control, setError, formState: { errors }, setValue} = useForm();
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);
    const [nonFieldErrorMessage, setNonFieldErrorMessage] = useState([]);  

    const onSubmit = async (data) => {
        setLoading(true);        


        await UsuarioAPI.changePassword(data).then(() => {
            setValue('current_password','');
            setValue('new_password','');
            setValue('repeat_password','');
            setToast(true);
        }).catch((error) => {
            const data = error.response.data;
            if (data.non_field_errors) {
                setNonFieldErrorMessage(data.non_field_errors);
            }
            else{
                for (const [key, value] of Object.entries(data)) {
                    setError(key, {
                        type: "manual",
                        message: value[0],
                    });
                }                    
            }
        });
        setLoading(false);
    }

    return (
        <>  
            <Helmet>
                <title>Alterar Senha</title>
            </Helmet>
            <Spinner open={loading} />
            <Title
                micon={<LockIcon/>}
                title={'Alterar'}
                subtitle={'Senha'}
            />
            <Container>
                <Paper elevation={0}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box p={3}>
                            <Controller
                                name={'current_password'}
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => {    
                                    return (                        
                                        <TextField
                                            label="Senha Atual"
                                            type="password"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={errors["current_password"]}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                            <ErrorMessage error={errors['current_password']} />
                            <Controller
                                name={'new_password'}
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => {    
                                    return (                          
                                        <TextField
                                            label="Nova Senha"
                                            type="password"
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={errors["password"]}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                            <ErrorMessage error={errors['new_password']} />
                            <Controller
                                name={'repeat_password'}
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => {    
                                    return (                               
                                        <TextField
                                            label="Confirmar Senha"
                                            type="password"
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={errors["repeat_password"]}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                            <ErrorMessage error={errors['repeat_password']} />
                        </Box>
                        <NonFieldErrorMessage non_field_errors={nonFieldErrorMessage} />  
                        <Divider />
                        <Box p={3}>
                            <Button startIcon={<SaveIcon />} type="submit" color="green">
                                Salvar
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </Container>
            <Toast
                open={toast}
                handleClose={() => setToast(false)}
                severity="success"
                duration={6000}
            >
                Senha alterada com sucesso!
            </Toast>

        </>
    )
}
