import React, {useState} from 'react';
import UsuarioAPI from '@services/usuariopendente';
import { CrudPage } from '@components/organisms';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import BlockIcon from '@material-ui/icons/Block';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { Dialog as MDialog } from '@components/molecules';
import {Autocomplete, NonFieldErrorMessage} from '@components/atoms';
import {PERFILACESSO} from '@services/usuario';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} color="blue">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        flex: 1,
        justifyContent: "space-between"
    },
}))(MuiDialogActions);
  


export default function UsuarioPendentePage() {
    const [dialog, setDialog] = useState(false);
    const [model, setModel] = useState(false);
    const [text, setText] = useState(false);
    const [id, setId] = useState(null);
    const [idPerfil, setIdPerfil] = useState(null);
    const [forceReload, setForceReload] = useState(false);
    const [errorPerfil, setErrorPerfil] = useState(null);

    const Modal = (props) => {
        
        const onSubmit = () => {
            if(idPerfil != null){
                UsuarioAPI.aprove({'id':id, 'perfil': idPerfil.id}).then(() => {
                    setForceReload(true);
                    setTimeout(function(){
                        setForceReload(false);
                    },1000);   
                    setErrorPerfil(null);   
                    setModel(false);                  
                }); 
            }else{
                setErrorPerfil('Escolha um perfil de acesso');
            }
        };
    
        return (
            <>
                <Dialog
                    aria-labelledby="import-dialog-title"
                    fullWidth
                    open={props.open}
                    maxWidth={"sm"}
                >
                    <DialogTitle id="import-dialog-title" onClose={props.onClose}>
                        {text}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Autocomplete
                            label={'Perfil de Acesso'}
                            options={PERFILACESSO}
                            optionLabel={(opt) => opt.label}
                            fullWidth
                            onChange={(e, v) => {
                                setIdPerfil(v);
                            }}
                            value={idPerfil}
                            variant={'standard'}
                        />    
                        <NonFieldErrorMessage non_field_errors={errorPerfil} />
                        
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={(e) => {
                                e.preventDefault();
                                onSubmit();
                            }}
                        >
                            Aprovar
                        </Button>
                    </DialogActions>
                </Dialog>            
            </>
        )
    }    

    return (
        <>
            <CrudPage
                api={UsuarioAPI}
                title={'Aprovações'}
                subtitle={'Pendentes'}
                app={'contas'}
                model={'usuario'}
                can_add={false}
                can_delete={false}
                can_update={false}
                forceReload={forceReload}
                listColumns={
                    [
                        {
                            field: 'id',
                            headerName: 'Id',
                            flex: 1
                        },
                        {
                            field: 'first_name',
                            headerName: 'Nome',
                            flex: 1
                        },
                        {
                            field: 'last_name',
                            headerName: 'Sobrenome',
                            flex: 1
                        },                        
                        {
                            field: 'email',
                            headerName: 'Email',
                            flex: 1
                        },  
                        {
                            field: 'aprovacao',
                            headerName: 'Aprovações',
                            flex: 1,
                            renderCell:(params) => {
                                return ( 
                                    <>
                                        <AssignmentTurnedInIcon style={{cursor:'pointer', color:'green'}} onClick={() => {
                                            setText(`Deseja realmente aprovar o usuário "${params.row.first_name} ${params.row.last_name}" ?`);
                                            setModel(true);
                                            setId(params.row.id);
                                            setIdPerfil(null);
                                            setErrorPerfil(null);
                                        }}
                                        
                                        />
                                        <BlockIcon style={{cursor:'pointer', color:'red', marginLeft:'10px'}} onClick={() => {
                                            setText(`Deseja realmente não aprovar o usuário "${params.row.first_name} ${params.row.last_name}" ?`);
                                            setDialog(true);
                                            setId(params.row.id);
                                        }}/>
                                    </>
                                )
                            }                             
                        },                                                                                                                       
                    ]
                }
           
                pageIcon={<DoneAllIcon />}
            />  
            <MDialog
                open={dialog}
                title={'Confirmação'}
                text={text}
                forceReload={forceReload}
                handleClose={() => { 
                    setDialog(''); 
                    setId(null);
                 }}
                handleSubmit={() => { 
                    setDialog(false); 
                    UsuarioAPI.deny({'id':id}).then(() => {
                        setForceReload(true);
                        setTimeout(function(){
                            setForceReload(false);
                        },1000);                        
                    });                
                }}
            /> 
            <Modal open={model} onClose={() => setModel(false)} />                                  
        </>
    );
}


