import React, {useState} from 'react';
import { Paper, Box, Container, TextField, Divider, Button } from '@material-ui/core';
import { Spinner, NonFieldErrorMessage, ErrorMessage } from '@components/atoms';
import { AuthHeader, Toast } from '@components/molecules';
import { useForm } from 'react-hook-form';
import { Controller } from "react-hook-form";
import {useParams} from "react-router-dom";
import SaveIcon from '@material-ui/icons/Save';
import UsuarioAPI from '@services/usuario';


export default function EsqueciMinhaSenhaPage() {

    const { handleSubmit, control, setError, formState: { errors }, setValue} = useForm();
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);
    const [nonFieldErrorMessage, setNonFieldErrorMessage] = useState([]);  

    const { hash } = useParams();

    const onSubmit = async (data) => {
        setLoading(true);        
        data['hash'] = hash;

        await UsuarioAPI.forgotPassword(data).then(() => {
            setValue('email','');
            setToast(true);
        }).catch((error) => {
            const data = error.response.data;
            if (data.non_field_errors) {
                setNonFieldErrorMessage(data.non_field_errors);
            }
            else{
                for (const [key, value] of Object.entries(data)) {
                    setError(key, {
                        type: "manual",
                        message: value[0],
                    });
                }                    
            }
        });
        setLoading(false);
    }

    return (
        <>
            <Spinner open={loading} />
            <AuthHeader title="Esqueci minha senha" />
            <Container>
                <Paper elevation={0}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box p={3}>
                            <Controller
                                name={'email'}
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => {    
                                    return (                               
                                        <TextField
                                            label="Email"
                                            type="email"
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={errors["email"]}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                            <ErrorMessage error={errors['email']} />
                        </Box>
                        <NonFieldErrorMessage non_field_errors={nonFieldErrorMessage} />  
                        <Divider />
                        <Box p={3}>
                            <Button startIcon={<SaveIcon />} type="submit" color="green">
                                Enviar
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </Container>
            <Toast
                open={toast}
                handleClose={() => setToast(false)}
                severity="success"
                duration={6000}
            >
                Você recebeu um email com instruções para gerar uma nova senha!
            </Toast>

        </>
    )
}