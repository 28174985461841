import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Logo from '@assets/logo/Prysmian_Logo_RGB_night.webp';


export default function AuthHeader({ title }) {
    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item sm={6} xs={12}>
                <Typography variant="h5">
                   {title}
                </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
                <Box textAlign="right">
                    <img src={Logo} alt="arena" style={{ width: '150px' }} />
                </Box>
            </Grid>
        </Grid>
    )
}