import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';


export default function Mask(props) {
    const { inputRef, mask, ...other } = props;
  
    return (
        <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={mask}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
}

Mask.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    mask: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
