import React from 'react';
import { Typography } from '@material-ui/core';


export default function PasswordTip({ password }) {
    // size
    const size = password.length >= 8;

    // number
    const number = password.match(/[0-9]/g);

    // letra minuscula
    const minuscula = password.match(/[a-z]/g);

    // letra maiuscula
    const maiuscula = password.match(/[A-Z]/g);

    // especial
    // eslint-disable-next-line
    const especial = password.match(/[()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?]/g);

    const tips = [
        { desc: 'Mínimo 8 caracteres, ', bool: !!size },
        { desc: 'conter 1 número, ', bool: !!number },
        { desc: 'letra minúscula, ', bool: !!minuscula },
        { desc: 'letra maiúsculo, ', bool: !!maiuscula },
        { desc: 'caracter especial.', bool: !!especial },
    ]

    return (
        <>
            {tips.map((tip, i) => (

                <Typography key={i} variant="caption" display="inline" color={tip.bool ? "primary" : "secondary"}>
                    {tip.desc}
                </Typography>

            ))}
        </>
    )
}