import React, { useEffect, useState } from 'react';
import { Toast, Title } from '@components/molecules';
import { Spinner, DatePicker, Autocomplete} from '@components/atoms';
import DashboardAPI from '@services/dashboard';
import { Helmet } from 'react-helmet';
import { Grid, Box } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment';
import {Button, Paper} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Line } from 'react-chartjs-2';
import ProdutoAPI from '@services/produto';
import ConcorrenteAPI from '@services/concorrente';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import { grey } from '@material-ui/core/colors';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



const useStyles = makeStyles({
    root: {
        display: 'flex',
        borderRadius: 4,
        backgroundColor: grey[50],
        width: '100%',
        '& .MuiDataGrid-root': {
            border: 'none'
        },
    },
    content: {
        flexGrow: 1,
    },
});

const HomePage = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [datapoint, setDataPoint] = useState(0);
    const [toast, setToast] = useState(null);  
    const [objectList, setObjectList] = useState([]);
    const { control:controlSearch, handleSubmit:handleSubmit2, setValue:setSearchValue, getValues: getSearchValues} = useForm({
        defaultValues:{
            ini: moment(new Date()).format('DD/MM/YYYY'),
            fim: moment(new Date()).format('DD/MM/YYYY')
        }
    });
    
    
    const handleSearchSubmit = async(data) => {
        // efetua  a busca
        setLoading(true);      
        try {
            if (data.produto){
                const produto = [];
                data['produto'].forEach((el) => {
                    produto.push(el.id);
                })
                data['produto'] = produto;
            }

            if (data.concorrente){
                const concorrente = [];
                data['concorrente'].forEach((el) => {
                    concorrente.push(el.id);
                })
                data['concorrente'] = concorrente;
            }


            const response = await DashboardAPI.list(data, null);
            console.log('response.data.data_point', response.data.data_point);
            setDataPoint(response.data.data_point);
            const charts = Object.entries(response.data.unit).map(([key,value])=>{
                return (
                    <>
                        <h4 style={{textAlign:'center'}}>Unidade: {key}</h4>
                        <PrecoUnitChart values={value.preco_unit} key={`p${key}`}/>
                        <h4 style={{textAlign:'center'}}>Por Estado</h4>
                        <PrecoEstadoChart values={value.estado} key={`e${key}`}/>
                    </>
                );
              })

            setObjectList(charts);
        } catch (error) {
            setToast({
                severity: "error",
                msg: 'Filtro informado é inválido.'
            });
        }
        setLoading(false);
    }    



    const PrecoUnitChart = (props) => {
        const values = props.values;
        const preco_prysmian = [];
        const preco_concorrente = [];
        const pdata = [];
        const dif = [];

        values.forEach(element => {
            preco_prysmian.push(element.preco_prysmian);
            preco_concorrente.push(element.preco_liquido);
            pdata.push(element.data);
            dif.push(element.dif);
            
        });
        const data = {
            labels: pdata,
            datasets: [
              {
                label: 'Preço Prysmian',
                data: preco_prysmian,
                // fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                dif:dif
              },
              {
                label: 'Preço Concorrente',
                data: preco_concorrente,
                // fill: false,
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgba(54, 162, 235, 0.2)',
                dif: dif
              },
            ],
          };
          

        return(
            <Line 
                data={data} 
                height={100}
                options={{
                    plugins: {
                        tooltip: {
                            callbacks: {
                                footer: function (context) {
                                    return 'Fab vs Pry: ' + context[0].dataset.dif[context[0].dataIndex] +'%';
                                },
                            }
                        },                                    
                    },                                
                }}             
                   
            />            
        )
    }

    const PrecoEstadoChart = (props) => {
        const values = props.values;
        const preco_prysmian = [];
        const preco_concorrente = [];
        const dif = [];
        const estado = [];

        values.forEach(element => {
            preco_prysmian.push(element.preco_prysmian);
            preco_concorrente.push(element.preco_liquido);
            estado.push(element.estado);
            dif.push(element.dif);
            
        });
        const data = {
            labels: estado,
            datasets: [
              {
                label: 'Preço Prysmian',
                data: preco_prysmian,
                // fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                dif: dif
              },
              {
                label: 'Preço Concorrente',
                data: preco_concorrente,
                // fill: false,
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgba(54, 162, 235, 0.2)',
                dif: dif

              },
            ],
          };

        return(
            <Line
             data={data} 
             height={100} 
             options={{
                plugins: {
                    tooltip: {
                        callbacks: {
                            footer: function (context) {
                                return 'Fab vs Pry: ' + context[0].dataset.dif[context[0].dataIndex] + '%';
                            },
                        }
                    },                                    
                },                                
            }}             
             
             />            
        )
    }    
 
    
    const FormFilter = () => {
        const renderOption = (option, { selected }) => (
            <React.Fragment>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.label}
            </React.Fragment>
        )   

    return (
        <form onSubmit={handleSubmit2(handleSearchSubmit)}>            
            <Grid
                container
                alignItems="center"
                spacing={2}
            >
                <Grid item sm={3} xs={12}>
                    <Box p={1}>
                        <Controller
                            name={'produto'}
                            control={controlSearch}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <>
                                        <Autocomplete
                                            limitTags={1}
                                            label={'Produto'}
                                            api={ProdutoAPI}
                                            optionLabel={(opt) => opt.label}
                                            fullWidth
                                            onChange={(e, v) => {
                                                onChange(v);
                                            }}
                                            value={value}
                                            renderOption={renderOption}
                                            multiple={true}
                                            disableCloseOnSelect={true}
                                        />                                                         
                                    </>
                                )
                            }}
                        />
                    </Box>
                </Grid>  
                <Grid item sm={3} xs={12}>
                    <Box p={1}>
                        <Controller
                            name={'concorrente'}
                            control={controlSearch}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <>
                                        <Autocomplete
                                            limitTags={1}
                                            label={'Concorrente'}
                                            api={ConcorrenteAPI}
                                            optionLabel={(opt) => opt.label}
                                            fullWidth
                                            onChange={(e, v) => {
                                                onChange(v);
                                            }}
                                            value={value}
                                            renderOption={renderOption}
                                            multiple={true}
                                            disableCloseOnSelect={true}
                                        />                                                            
                                    </>
                                )
                            }}
                        />
                    </Box>
                </Grid>                                                                               
                <Grid item sm={2} xs={12}>
                    <Box p={1}>
                        <Controller
                            name={'ini'}
                            control={controlSearch}
                            render={({ field }) => {
                                return (
                                    <>
                                        <DatePicker
                                            fullWidth 
                                            label={'Data Início'} 
                                            onChange={(date) => setSearchValue('ini', moment(date).format('DD/MM/YYYY')) }     
                                            variant={'outlined'} 
                                            value={moment(getSearchValues('ini'), 'DD/MM/YYYY')}                                                          
                                        />                                                            
                                    </>
                                )
                            }}
                        />
                    </Box>
                </Grid>                                            
                <Grid item sm={2} xs={12}>
                    <Box p={1}>
                        <Controller
                            name={'fim'}
                            control={controlSearch}
                            render={({ field }) => {
                                return (
                                    <>
                                        <DatePicker
                                            fullWidth 
                                            label={'Data Final'} 
                                            onChange={(date) => setSearchValue('fim', moment(date).format('DD/MM/YYYY')) }     
                                            variant={'outlined'} 
                                            value={moment(getSearchValues('fim'), 'DD/MM/YYYY')}                                                          
                                        />                                                            
                                    </>
                                )
                            }}
                        />
                    </Box>
                </Grid>                                      
                <Grid item xs={12} sm={2}>
                    <Button type="submit" color="primary" variant="outlined">
                        Aplicar
                    </Button>           
                </Grid>
                {datapoint > 0 &&
                    <Grid item sm={12} style={{paddingLeft:'15px'}}>
                        Data Point: {datapoint}
                    </Grid>
                }
            </Grid>
        </form>        
    )}


    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Title
                // icon={props.icon}
                title={'Dashboard'}
                subtitle={'Pesquisa Preço'}
            />            
            <Spinner open={loading} />
            <Box px={2} mb={2}>
                <div className={classes.root}>
                    <div className={classes.content}>            
                        <Paper elevation={0}>
                            <Box p={2}>
                                <FormFilter/>
                            </Box>
                            <Box>
                            <Grid >
                                <Grid item xs={12} sm={12}>
                                    {objectList}
                                </Grid>
                            </Grid>
                            </Box>
                        </Paper>            
                    </div>
                </div>
            </Box>
            {toast && (
                <Toast
                    open={!!toast}
                    severity={toast && toast.severity}
                    handleClose={() => { setToast(null); }}
                    duration={3000}
                >
                    {toast && toast.msg}
                </Toast>
            )}            
        </>
    )

}



export default HomePage;