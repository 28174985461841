import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.tooltip + 1,
        color: '#fff',
    },
}));


export default function Spinner({ ...props }) {
    const classes = useStyles();
    
    return (
        <Backdrop className={classes.backdrop} open={props.open}>
            <CircularProgress color="primary" />
        </Backdrop>
    )
}
