import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { AuthHeader } from '@components/molecules';
import urls from '@urls';
import { Helmet } from 'react-helmet';


export default function FinalizadoPage({ history }) {
    return (
        <>
            <Helmet>
                <title>Cadastro Finalizado - Pesquisa Preço</title>
            </Helmet>
            <AuthHeader title="Cadastro Finalizado!!!" />
            <Box my={4} textAlign="center">
                <Typography variant="h6">
                    Aguarde seu cadastro ser aprovado pelo departamento Comercial, você receberá um email assim que for aprovado!
                </Typography>
            </Box>
            <Button variant="outlined" color="primary" onClick={(e) => { e.preventDefault(); history.push(urls.acesso.login); }} fullWidth>
                Voltar
            </Button>
        </>
    )
}