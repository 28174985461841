import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function AlertDialog(props) {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                keepMounted
            >
                {props.title &&
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="h6" color="default">
                            {props.title}
                        </Typography>
                    </DialogTitle>
                }
                {props.text &&
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="subtitle2">
                                {props.text}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                }
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={props.handleSubmit} variant="contained" color="primary">
                        Confirmar
                    </Button>                    
                    <Button onClick={props.handleClose} variant="outlined" color="secondary" autoFocus>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}