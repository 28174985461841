function hasPermissions(permissions, app, model){
    let perms = [
        `${app}.delete_${model}`,
        `${app}.add_${model}`,
        `${app}.change_${model}`,
        `${app}.view_${model}`,
        `${app}.${model}`,
    ]
    return permissions.some(item => perms.includes(item));
}

function getPermissions(permissions, app, model){
    let permission = (permissions || []);
    let perms = [
        `${app}.delete_${model}`,
        `${app}.add_${model}`,
        `${app}.change_${model}`,
        `${app}.view_${model}`,

    ]
    return perms.filter(function(n) {
        return permission.indexOf(n) !== -1;
    });
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function hasPermission(permissions, permission){
    return permission.indexOf(permission) !== -1;
}


export {
    hasPermissions,
    getPermissions,
    capitalizeFirstLetter,
    hasPermission
}