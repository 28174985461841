import { BaseAPIClass } from './api';


class ConcorrenteAPI extends BaseAPIClass{
    constructor() {
        super('/entidades/concorrente/');
      }     
}


export default new ConcorrenteAPI();
