import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function Toast({ children, ...props }) {
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if(props.handleClose)
            props.handleClose();
    };

    return (
        <div className={classes.root}>
            <Snackbar 
                autoHideDuration={props.duration || 6000}
                onClose={handleClose}
                anchorOrigin={props.anchorOrigin || { vertical: 'top', horizontal: 'center' }}
                open={props.open} 
            >
                <Alert onClose={handleClose} severity={props.severity || "info"}>
                    {children}
                </Alert>
            </Snackbar>
        </div>
    );
}