import { BaseAPIClass } from './api';


class AliquotaAPI extends BaseAPIClass{
    constructor() {
        super('/impostos/aliquota/');
      }     
}


export default new AliquotaAPI();
