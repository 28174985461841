import React from 'react';
import API from '@services/aliquota';
import { CrudPage } from '@components/organisms';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';



export default function AliquotaICMSPage() {
    return (
        <>
            <CrudPage
                api={API}
                title={'Alíquotas ICMS'}
                subtitle={'Impostos'}
                app={'impostos'}
                model={'aliquotaicms'}
                listColumns={
                    [
                        {
                            field: 'valor',
                            headerName: 'Alíquota',
                            flex: 1
                        },
                    ]
                }
                fields={
                    [
                        {
                            type: 'decimal',
                            name: 'valor',
                            label: 'Alíquota',
                            required: true
                        },
                    ]
                }                
                pageIcon={<EmojiSymbolsIcon />}
            />
        </>
    );
}