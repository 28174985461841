import React from 'react';
import API from '@services/unidademedida';
import { CrudPage } from '@components/organisms';
import AcUnitIcon from '@material-ui/icons/AcUnit';



export default function UnidadeMedidaPage() {

    return (
        <>
            <CrudPage
                api={API}
                title={'Unidade'}
                subtitle={'Medida'}
                app={'produtos'}
                model={'unidademedida'}
                listColumns={
                    [
                        {
                            field: 'descricao',
                            headerName: 'Descrição',
                            flex: 1
                        },
                                              
                    ]
                }
                fields={
                    [                    
                        {
                            type: 'textbox',
                            name: 'descricao',
                            label: 'Descrição',
                        },                        
                    ]
                }
                search={
                    [
                        {
                            type: 'textbox',
                            name: 'search',
                            label: 'Pesquise'
                        }
                    ]

                }
                pageIcon={<AcUnitIcon />}
            >
            </CrudPage>
        </>
    );
}