import { BaseAPIClass } from './api';


class ImpostoAPI extends BaseAPIClass{
    constructor() {
        super('/impostos/imposto/');
      }     
}


export default new ImpostoAPI();
