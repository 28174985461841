import React, { useContext, createContext, useState, useEffect } from 'react';
import { setToken, getToken, getUser } from '@services/storage';
import UsuarioAPI from '@services/usuario';


const InitialAuthContext = {
    isAuthenticated: null,
    loading: null,
    user: {},
    login() {},
    logout() {},
    permissions: [],
    setPermssions(){}
}


const AuthContext = createContext(InitialAuthContext);


function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    useEffect(() => {
        async function checkPermission() {
            if(isAuthenticated) {
                const response = await UsuarioAPI.getPermissions();
                setPermissions(response.data.permissions);
                setUser(response.data.user);
                setLoading(false);
            }
        }
        checkPermission();
    }, [isAuthenticated]);    

    useEffect(() => {
        async function checkStorage() {
            const token = await getToken();
            if(token) {
                setIsAuthenticated(true);
                setLoading(true);
            }else{
                setLoading(false);
            }
        }
        checkStorage();
    }, []);

    function login(token, user) {
        setToken(token);
        setLoading(true);
        setIsAuthenticated(true);
    }

    function logout() {
        localStorage.clear();
        setUser(null);
        setPermissions([]);
        setIsAuthenticated(false);
        window.location.href = '/acesso/login';
    }

    
    return (
        <AuthContext.Provider value={{ isAuthenticated: isAuthenticated, user, loading, permissions, login, logout }}>
            { children }
        </AuthContext.Provider>
    );
}


function useAuth() {
    return useContext(AuthContext);
}


export { useAuth, AuthProvider }
