import { BaseAPIClass } from './api';


class UnidadeAPI extends BaseAPIClass{
    constructor() {
        super('/produtos/unidade/');
      }  
}


export default new UnidadeAPI();
