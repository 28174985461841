import React from 'react';
import API from '@services/lista_preco';
import ProdutoAPI from '@services/produto';
import { CrudPage } from '@components/organisms';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';



export default function ListaPrecoPage() {

    return (
        <>
            <CrudPage
                api={API}
                title={'Lista'}
                subtitle={'Preço'}
                app={'produtos'}
                model={'listapreco'}
                listColumns={
                    [
                        {
                            field: 'produto',
                            headerName: 'Produto',
                            flex: 1
                        },
                        {
                            field: 'data_inicio',
                            headerName: 'Data Início',
                            flex: 1
                        },
                        {
                            field: 'data_fim',
                            headerName: 'Data Fim',
                            flex: 1
                        },
                        {
                            field: 'preco',
                            headerName: 'Preço',
                            flex: 1,
                            renderCell:(params) => {
                                return params.value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(params.value) : ''
                            }                                                       
                        },                        
                                              
                    ]
                }
                fields={
                    [                    
                        {
                            type:'autocomplete',
                            name:'produto', 
                            label: 'Produto',
                            required: true,
                            api: ProdutoAPI,
                        },

                        {
                            type: 'datepicker',
                            name: 'data_inicio',
                            label: 'Dt. Início',
                            required: true
                        },
                        {
                            type: 'datepicker',
                            name: 'data_fim',
                            label: 'Data Final',
                        },
                        {
                            type: 'money',
                            name: 'preco',
                            label: 'Preço',
                        },                        
                    ]
                }
                search={
                    [
                        {
                            type: 'textbox',
                            name: 'search',
                            label: 'Pesquise'
                        }
                    ]

                }
                pageIcon={<LocalAtmIcon />}
            >
            </CrudPage>
        </>
    );
}