import { useMemo } from 'react';
import axios from 'axios';
import { getToken } from '@services/storage';
import { useAuth } from '@context/auth';

const API_DEV = 'http://127.0.0.1:8000/api/v1/';
// const API_DEV = 'http://api.pesquisapreco.marketingprysmian.com.br/api/v1/';
const API_PROD = 'https://api.pesquisapreco.marketingprysmian.com.br/api/v1/';
const api = axios.create({ baseURL: process.env.NODE_ENV === 'production' ? API_PROD : API_DEV });

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    Promise.reject(error)
});

//Add a response interceptor
const WithAxios = ({ children }) => {
    const { logout } = useAuth();

    useMemo(() => {
        api.interceptors.response.use((response) => {
            return response;
        }, function (error) {

            if (error.response.status === 401) {
                logout();
                return Promise.reject(error);
            }

            return Promise.reject(error);
        });
    }, [logout])
    return children;
}

class BaseAPIClass {
    constructor(path) {
        this.path = path;
    }

    list = async (data, link) => {
        if (link)
            return api.get(link)
        return api.get(this.path, { params: data });
    }

    detail = async (id) => {
        return api.get(this.path + id + '/');
    }

    update = async (id, json) => {
        return api.put(this.path + id + '/', json);
    }

    create = async (json) => {
        return api.post(this.path, json);
    }

    delete = async (id) => {
        return api.delete(this.path + id + '/');
    }

    getSelectOptions = async (data) => {
        return api.get(this.path + 'select/', { params: data });
    }

}

export default api;


export {
    BaseAPIClass,
    WithAxios
}
