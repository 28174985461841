import React from 'react';
import UsuarioAPI, {PERFILACESSO} from '@services/usuario';
import { CrudPage } from '@components/organisms';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

export default function UsuarioPage() {
    return (
        <>
            <CrudPage
                api={UsuarioAPI}
                title={'Usuários'}
                subtitle={'Gestão'}
                app={'contas'}
                model={'usuario'}
                listColumns={
                    [
                        {
                            field: 'id',
                            headerName: 'Id',
                            flex: 1
                        },
                        {
                            field: 'first_name',
                            headerName: 'Nome',
                            flex: 1
                        },
                        {
                            field: 'last_name',
                            headerName: 'Sobrenome',
                            flex: 1
                        },                        
                        {
                            field: 'email',
                            headerName: 'Email',
                            flex: 1
                        },   
                        {
                            field: 'is_active',
                            headerName: 'Ativo?',
                            flex: 1
                        },    
                        {
                            field: 'tipo',
                            headerName: 'Tipo',
                            flex: 1
                        },                                                                                               
                    ]
                }
                fields={
                    [
                        {
                            type: 'textbox',
                            inputType: 'email',
                            name: 'email',
                            label: 'E-mail',
                            required: true,
                        },                            
                        {
                            type: 'textbox',
                            name: 'first_name',
                            label: 'Primeiro Nome',
                            required: true,
                            sm: 6
                        },     
                        {
                            type: 'textbox',
                            name: 'last_name',
                            label: 'Último Nome',
                            required: true,
                            sm: 6
                        }, 
                        {
                            type: 'autocomplete',
                            name: 'tipo',
                            label: 'Perfil Acesso',
                            required: true,
                            options: PERFILACESSO
                        }, 
                        {
                            type: 'iosswitch',
                            name: 'is_active',
                            label: 'Ativo',
                            required: true,
                            sm: 6
                        },                                                                                                               
                    ]
                }
                
                add_fields={
                    [
                        {
                            type: 'textbox',
                            inputType: 'email',
                            name: 'email',
                            label: 'E-mail',
                            required: true,
                        },                            
                        {
                            type: 'textbox',
                            name: 'first_name',
                            label: 'Primeiro Nome',
                            required: true,
                            sm: 6
                        },     
                        {
                            type: 'textbox',
                            name: 'last_name',
                            label: 'Último Nome',
                            required: true,
                            sm: 6
                        }, 
                        {
                            type: 'autocomplete',
                            name: 'tipo',
                            label: 'Perfil Acesso',
                            required: true,
                            options: PERFILACESSO
                        },                                                                                                                   
                    ]
                }                  
                pageIcon={<PeopleAltIcon />}
            />             
        </>
    );
}