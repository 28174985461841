import React from 'react';
import API from '@services/concorrente';
import { CrudPage } from '@components/organisms';
import ContactsIcon from '@material-ui/icons/Contacts';


export default function ConcorrentePage() {
    return (
        <>
            <CrudPage
                api={API}
                title={'Concorrentes'}
                subtitle={'Entidade'}
                app={'produtos'}
                model={'produto'}
                listColumns={
                    [
                        {
                            field: 'nome',
                            headerName: 'Nome',
                            flex: 1
                        },
                    ]
                }
                fields={
                    [
                        {
                            type: 'textbox',
                            name: 'nome',
                            required: true
                        },
                    ]
                }
                search={
                    [
                        {
                            type:'textbox',
                            name: 'search', 
                            label:'Pesquise'
                        }
                    ]
    
                }
                pageIcon={<ContactsIcon />}

            />
        </>
    );
}