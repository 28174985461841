import React from 'react';
import PasswordTip from './password_tip';
import MTextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';

export default function PasswordField(props) {
    const { name, label, control, ...others } = props;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                    <MTextField
                        {...others}
                        onChange={onChange}
                        value={value}
                        label={label}
                        type="password"
                    />
                    <PasswordTip password={value || ''} />
                </>
            )}
        />

    );
}