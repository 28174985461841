import api, {BaseAPIClass} from './api';



class UsuarioAPI extends BaseAPIClass{
    constructor() {
        super('/contas/usuario/');
    } 

    login = async (data) => { 
        return await api.post(`${this.path}authentication/`, data);
    }

    getPermissions = async() => {
        return api.get(`${this.path}permissions/`);
    }
    validateEmailCode = async(data) =>{
        return api.post(`${this.path}validateCode/`, data)
    }

    changePassword = async(data) => { 
        return await api.patch(`${this.path}alterar_senha/`, data);
    }   
    
    newPassword = async(data) => { 
        return await api.patch(`${this.path}nova_senha/`, data);
    }      
    
    forgotPassword = async(data) => { 
        return await api.patch(`${this.path}esqueci_senha/`, data);
    }     

    register = async(data) => { 
        return await api.post(`${this.path}register/`, data);
    }       
}


export default new UsuarioAPI();

const PERFILACESSO = [
    {'id': 1, label: 'Vendedor / Representante Comercial'},
    {'id': 2, label: 'Comercial'},
    {'id': 3, label: 'Marketing'},
    {'id': 0, label: 'Administrador'}
]

export {
    PERFILACESSO
}