import api, { BaseAPIClass } from './api';


class ProdutoAPI extends BaseAPIClass{
    constructor() {
        super('/produtos/produto/');
      }  

    upload = async (file) => {
      var formData = new FormData();
      formData.append("xls", file);

        return api.patch(this.path +'uploadxls/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }})
    }
}


export default new ProdutoAPI();
