import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} color="blue">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        flex: 1,
        justifyContent: "space-between"
    },
}))(MuiDialogActions);

const Modal = ({ children, ...props }) => {
    return (

        <Dialog aria-labelledby="customized-dialog-title" fullWidth open maxWidth={props.modalMaxWidth || "sm"} fullScreen={props.fullScreen || false}>
            <form onSubmit={props.handleSubmit}>
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    {props.title}
                </DialogTitle>
                <DialogContent dividers>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        Salvar
                    </Button>                    
                    {props.cancel !== false &&
                        <Button onClick={props.handleClose} variant="outlined" color="secondary">
                            Cancelar
                        </Button>
                    }
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default Modal;
