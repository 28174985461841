import { BaseAPIClass } from './api';


class EstadoAPI extends BaseAPIClass{
    constructor() {
        super('/localidade/estado/');
      }     
}


export default new EstadoAPI();
