import {useEffect} from 'react';
import Routes from './routes';
import { AuthProvider } from './context/auth';
import { WithAxios } from './services/api';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './themes';


// const loader = document.querySelector(".bundleLoader");

// const removeHTMLLoader = () => loader.classList.remove("bundleLoader");

export default function App() {
    // useEffect(() => {
    //     removeHTMLLoader();
    //   }, 
    // []);    
    
    return (
        <AuthProvider>
            <WithAxios>
                <ThemeProvider theme={theme}>
                    <Routes />
                </ThemeProvider>
            </WithAxios>
        </AuthProvider>
    )
}
