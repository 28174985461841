const TOKEN = '@prysmian-Token';
const USER = '@prysmian-User';


const KEYS = {
    TOKEN: TOKEN,
    USER: USER,
}


const setToken = (value) => {
    localStorage.setItem(KEYS.TOKEN, value);
}

const getToken = () => {
    return localStorage.getItem(KEYS.TOKEN);
}

export {
    setToken, 
    getToken,
}
