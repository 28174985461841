import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import grey from '@material-ui/core/colors/grey';
import Logo from '@assets/logo/Prysmian_Logo_RGB_Negative.webp';
import { useAuth } from '@context/auth';
import GavelIcon from '@material-ui/icons/Gavel';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ContactsIcon from '@material-ui/icons/Contacts';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import urls from '@urls';
import { useHistory } from 'react-router';
import {hasPermissions} from '@services/utils';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ShowChartIcon from '@material-ui/icons/ShowChart';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: theme.palette.night.main,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.night.main,
        color: 'white',
        "& svg": {
            color: theme.palette.primary.main
        },
        // [theme.breakpoints.down('sm')]: {
        //     display: 'none'
        // },
    },
    drawerOpen: {
        backgroundColor: theme.palette.night.main,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden'
    },
    drawerClose: {
        backgroundColor: theme.palette.night.main,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // [theme.breakpoints.up('sm')]: { matheus
        //     paddingLeft: 0
        // },
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,

    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.darkGrey.main,
        minHeight:'100vh'
    },
}));

export default function Sidebar({ children }) {
    const { innerWidth: width } = window;
    const classes = useStyles();
    const theme = useTheme();
    const { user, logout } = useAuth();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const [openMenu, setOpenMenu] = useState(null);
    const [open, setOpen] = useState(width > 400);
    const anchorRef = useRef();
    const { permissions } = useAuth();
    const [menuList, setMenuList] = useState([]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    const handleToggleMenu = () => {
        setOpenMenu(prevOpenMenu => !prevOpenMenu);
    }

    useEffect(() => {
        async function checkPermission() {
            setMenuList(getMenu(permissions));                    
        }
        checkPermission();
    }, [permissions]);    

    const getMenu = (permission) => {    
        const menuList = [
            {
                nome: 'Dashboard',
                path: urls.index,
                icon: <ShowChartIcon sx={{color: "#fafafa"}} />,
                permission: hasPermissions(permission, 'pesquisa_preco', 'itens')     
            },               
            {
                nome: 'Pesquisa de Preço',
                path: urls.pesquisapreco.pesquisapreco,
                icon: <FindReplaceIcon />,
                permission: hasPermissions(permission, 'pesquisa_preco', 'pesquisapreco')   
            },   
            {
                nome: 'Histórico',
                path: urls.pesquisapreco.historico,
                icon: <ChangeHistoryIcon />,
                permission: hasPermissions(permission, 'pesquisa_preco', 'itens')   
            },                     
            {
                nome: 'Produtos',
                path: urls.produtos.produto,
                icon: <SettingsInputHdmiIcon />,
                permission: hasPermissions(permission, 'produtos', 'produto')   
            },
            {
                nome: 'Lista de Preço',
                path: urls.produtos.listapreco,
                icon: <LocalAtmIcon />,
                permission: hasPermissions(permission, 'produtos', 'listapreco')   
            },  
            {
                nome: 'Unidade de Medida',
                path: urls.produtos.unidademedida,
                icon: <AcUnitIcon />,
                permission: hasPermissions(permission, 'produtos', 'unidademedida')   
            },                      
            {
                nome: 'Impostos',
                path: urls.impostos.imposto,
                icon: <GavelIcon />,
                permission: hasPermissions(permission, 'impostos', 'imposto')   
            },
            {
                nome: 'Alíquota ICMS',
                path: urls.impostos.aliquota,
                icon: <EmojiSymbolsIcon />,
                permission: hasPermissions(permission, 'impostos', 'aliquotaicms')   
            },  
            {
                nome: 'Concorrente',
                path: urls.entidades.concorrente,
                icon: <ContactsIcon/>,
                permission: hasPermissions(permission, 'entidades', 'concorrente'),
            },                      
            {
                nome: 'Usuários',
                path: urls.usuarios.usuario,
                icon: <PeopleAltIcon />,
                permission: hasPermissions(permission, 'contas', 'usuario')   
            },
            {
                nome: 'Aprovações',
                path: urls.usuarios.pendentes,
                icon: <DoneAllIcon color={"primary.main"} />,
                permission: hasPermissions(permission, 'contas', 'usuario')   
            }            
        ]
        return menuList;    
    }
    
    const list = () => (
        <>
            {menuList.map((item, index) => (
                <>
                    {item.permission &&
                        <>
                            <List className={classes.drawer}> {/* Pra ficar 100% no mobile */}
                                <ListItem button key={index} onClick={() => history.push(item.path)}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>                                
                                    <ListItemText primary={item.nome} />
                                </ListItem>                                        
                            </List>
                            {/* <Divider /> */}
                        </>
                    }
                </>
            ))}
        </>
    )

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box ml="auto">
                        <Button
                            ref={anchorRef}
                            aria-controls="user-menu"
                            aria-haspopup="true"
                            onClick={handleToggleMenu}
                            startIcon={<PersonIcon />}
                            variant="contained"
                            color="primary"
                            disableElevation
                        >
                            {user && user.fullname.split(' ')[0]}
                        </Button>
                        <Menu
                            id="user-menu"
                            anchorEl={anchorRef.current}
                            keepMounted
                            open={openMenu}
                            onClose={handleMenuClose}
                        >
                            <MenuItem  onClick={() => {
                                history.push(String(urls.acesso.alterarsenha));
                            }}>Alterar senha</MenuItem>
                            <MenuItem onClick={logout}>Sair</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={open}
                variant={!matches ? "permanent" : "temporary"}
                onClose={handleDrawerClose}
                className={clsx(classes.drawer, !matches && {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(!matches && {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <img src={Logo} height="35" alt="logo" />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                {list()}
            </Drawer>

            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    );
}