import { createTheme } from '@material-ui/core/styles';


const theme = createTheme({
    palette: {
        primary: {
            light: '#29D9BE',
            main: '#00D160',
            dark: '#11593E',
            contrastText: '#fff',
        },
        secondary: {
            light: '#6a7fa9',
            main: '#0093FF',
            dark: '#44526e',
            contrastText: '#fff',
        },
        lightGrey:{
            main: "#DCE4F2"
        },
        darkGrey:{
            main: "#2D354A"
        },
        night: {
            main: "#191B27"
        }
    },
});


export default theme;
