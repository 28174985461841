import React from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import { Controller } from "react-hook-form";
import {
    ErrorMessage,
    Autocomplete,
    DateMask,
    MoneyFormatCustom,
    DecimalCustom,
    Mask,
    IOSSwitch,
    DatePicker,
    ImageUpload
} from '@components/atoms';
import { Grid, Box } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { capitalizeFirstLetter } from '@services/utils';
import moment from 'moment';


const GetFieldName = (formset, inlineIndex, item) => {
    if (formset) {
        return `${formset}_${inlineIndex}_${item.name}`;
    } else {
        return item.name;
    }
}


const InputWrapper = (props) => {
    const defaultShow = typeof (props.item.defaultShow) === 'undefined' ? true : props.item.defaultShow;
    const show = typeof (props.show) === 'undefined' ? defaultShow : props.show;
    const display = show ? 'block' : 'none';
    return (
        <>
            <Grid key={props.idx} item sm={props.item.sm || 12} xs={props.item.xs || 12} style={{ display: display }}>
                <Box p={1}>
                    {props.children}
                </Box>
            </Grid>
        </>
    )
};


const CrudsEditTextBox = (props) => {
    const {
        idx,
        item,
        fieldsRef,
        control,
        errors,
        setValue,
        formset,
        inlineIndex,
        fieldWrapperVisible
    } = props;
    const name = GetFieldName(formset, inlineIndex, item);
    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                rules={{ required: item.required || false }}
                render={({ field }) => {
                    return (
                        <>
                            <TextField
                                inputRef={fieldsRef ? fieldsRef[name] : null}
                                fullWidth
                                type={item.inputType || "text"}
                                // autoFocus 
                                label={item.label || capitalizeFirstLetter(item.name)}
                                error={errors[name]}
                                {...field}
                                onBlur={(e) => {
                                    if (item.onBlur) {
                                        const usecruds = () => ({
                                            'setValue': setValue,
                                            'fieldsRef': fieldsRef,
                                        });
                                        item.onBlur(e.target.value, usecruds);
                                    }
                                }}
                                multiline={item.multiline || false}
                            />
                            <ErrorMessage error={errors[name]} />
                        </>
                    )
                }}
            />
        </InputWrapper>
    )
};


const CrudsAutoComplete = (props) => {
    const {
        idx,
        item,
        control,
        errors,
        getValues,
        formset,
        inlineIndex,
        setValue,
        setTabDisabled,
        setTabValue,
        fieldWrapperVisible,
        setFieldFieldWrapperVisible
    } = props;
    const name = GetFieldName(formset, inlineIndex, item);

    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                defaultValue={item.multiple?[]:null}
                onChange={([val, obj]) => obj}
                rules={{ required: item.required || false }}
                render={({ field: { onChange, value } }) => {
                    return (
                        <>  <Autocomplete
                            label={item.label || capitalizeFirstLetter(item.name)}
                            api={item.api}
                            forward={item.forward}
                            options={item.options}
                            multiple={item.multiple || false}
                            formset={formset}
                            inlineIndex={inlineIndex}
                            getValues={getValues}
                            optionLabel={(opt) => opt.label}
                            fullWidth
                            error={errors[name]}
                            disableCloseOnSelect={item.disableCloseOnSelect}
                            renderOption={item.renderOption}
                            disabled={item.disabled || false}
                            onChange={(e, v) => {
                                onChange(v);
                                if (item.onChange) {
                                    const usecruds = () => ({
                                        'setValue': setValue,
                                        'formset': formset,
                                        'inlineIndex': inlineIndex,
                                        'setTabDisabled': setTabDisabled,
                                        'setTabValue': setTabValue,
                                        'setFieldFieldWrapperVisible': setFieldFieldWrapperVisible,
                                        'getValues': getValues
                                    });
                                    item.onChange(v, usecruds);
                                }
                            }}
                            value={value}
                            variant={'standard'}
                        />
                            <ErrorMessage error={errors[name]} />
                        </>
                    )
                }}
            />
        </InputWrapper>
    )
};

const CrudsCheckBox = (props) => {
    const { idx, item, control, watch, formset, inlineIndex, fieldWrapperVisible, setValue, setFieldFieldWrapperVisible } = props;
    const name = GetFieldName(formset, inlineIndex, item);

    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                color="primary"
                                defaultChecked={watch(name)}
                                onChange={(e) => {
                                    if (item.onChange) {
                                        const usecruds = () => ({
                                            'setValue': setValue,
                                            'formset': formset,
                                            'inlineIndex': inlineIndex,
                                            'setFieldFieldWrapperVisible': setFieldFieldWrapperVisible
                                        });
                                        item.onChange(e, usecruds);
                                    }
                                }}
                            />
                        }
                        label={item.label || capitalizeFirstLetter(item.name)}
                    />
                )}
            />
        </InputWrapper>
    )
};

const CrudsIOSSwitch = (props) => {
    const { idx, item, control, watch, formset, inlineIndex, fieldWrapperVisible } = props;
    const name = GetFieldName(formset, inlineIndex, item);

    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                {...field}
                                defaultChecked={watch(name)}
                            />
                        }
                        label={item.label || capitalizeFirstLetter(item.name)}
                    />
                )}
            />
        </InputWrapper>
    )
};

const CrudsMoney = (props) => {
    const { idx, item, control, errors, formset, inlineIndex, fieldWrapperVisible } = props;
    const name = GetFieldName(formset, inlineIndex, item);
    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                rules={{ required: item.required || false }}
                render={({ field }) => (
                    <>
                        <TextField
                            fullWidth
                            autoFocus
                            label={item.label || capitalizeFirstLetter(item.name)}
                            error={errors[name]}
                            {...field}
                            InputProps={{
                                inputComponent: MoneyFormatCustom,
                            }}
                        />
                        <ErrorMessage error={errors[name]} />
                    </>
                )}
            />
        </InputWrapper>
    )
};

const CrudsDecimal = (props) => {
    const { idx, item, control, errors, formset, inlineIndex, fieldWrapperVisible } = props;
    const name = GetFieldName(formset, inlineIndex, item);

    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                defaultValue={item.defaultValue || ""}
                rules={{ required: item.required || false }}
                render={({ field }) => (
                    <>
                        <TextField
                            fullWidth
                            // autoFocus 
                            label={item.label || capitalizeFirstLetter(item.name)}
                            error={errors[name]}
                            {...field}
                            InputProps={{
                                inputComponent: DecimalCustom,
                                inputProps: { decimalScale: item.decimalScale }
                            }}
                        />
                        <ErrorMessage error={errors[name]} />
                    </>
                )}
            />
        </InputWrapper>
    )
};

const CrudsDatePicker = (props) => {
    const { idx, item, control, errors, formset, inlineIndex, fieldWrapperVisible, setValue } = props;
    const name = GetFieldName(formset, inlineIndex, item);
    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                value={item.defaultValue || null}
                rules={{ required: item.required || false }}
                render={({ field: { onChange, value } }) => {
                    return (
                        <>
                            <DatePicker
                                fullWidth
                                label={item.label || capitalizeFirstLetter(item.name)}
                                error={errors[name]}
                                value={moment(value, 'DD/MM/YYYY')}
                                onChange={(date) => {
                                    if (date != null)
                                        setValue(name, moment(date).format('DD/MM/YYYY'));
                                    else {
                                        setValue(name, null);
                                    }
                                }}
                            />
                            <ErrorMessage error={errors[name]} />
                        </>)
                }}
            />
        </InputWrapper>
    )
};



const CrudsDateMask = (props) => {
    const { idx, item, control, errors, formset, inlineIndex, fieldWrapperVisible } = props;
    const name = GetFieldName(formset, inlineIndex, item);

    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                defaultValue={item.defaultValue || ""}
                rules={{ required: item.required || false }}
                render={({ field }) => (
                    <>
                        <TextField
                            fullWidth
                            // autoFocus 
                            label={item.label || capitalizeFirstLetter(item.name)}
                            error={errors[name]}
                            {...field}
                            InputProps={{
                                inputComponent: DateMask,
                            }}
                        />
                        <ErrorMessage error={errors[name]} />
                    </>
                )}
            />
        </InputWrapper>
    )
};

const CrudsMask = (props) => {
    const { idx, item, control, errors, formset, inlineIndex, fieldWrapperVisible } = props;
    const name = GetFieldName(formset, inlineIndex, item);

    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                defaultValue={item.defaultValue || ""}
                rules={{ required: item.required || false }}
                render={({ field }) => (
                    <>
                        <TextField
                            fullWidth
                            // autoFocus 
                            label={item.label || capitalizeFirstLetter(item.name)}
                            error={errors[name]}
                            {...field}
                            InputProps={{
                                inputComponent: Mask,
                                inputProps: { mask: item.mask }
                            }}

                        />
                        <ErrorMessage error={errors[name]} />
                    </>
                )}
            />
        </InputWrapper>
    )
};


const CrudsImageUpload = (props) => {
    const { idx, item, control, errors, formset, inlineIndex, fieldWrapperVisible, setValue } = props;
    const name = GetFieldName(formset, inlineIndex, item);
    return (
        <InputWrapper idx={idx} item={item} show={fieldWrapperVisible[name]}>
            <Controller
                name={name}
                control={control}
                value={item.defaultValue || null}
                rules={{ required: item.required || false }}
                render={({ field: { onChange, value } }) => {
                    return (
                        <>
                            {item.label || capitalizeFirstLetter(item.name)}
                            <ImageUpload
                                id={name}
                                value={value}
                                onChange={(value) => {
                                    if (value != null)
                                        setValue(name, value);
                                    else {
                                        setValue(name, null);
                                    }
                                }}
                            />
                            <ErrorMessage error={errors[name]} />
                        </>)
                }}
            />
        </InputWrapper>
    )
};

export {
    CrudsEditTextBox,
    CrudsAutoComplete,
    CrudsCheckBox,
    CrudsIOSSwitch,
    CrudsMoney,
    CrudsDecimal,
    CrudsDateMask,
    CrudsMask,
    CrudsDatePicker,
    CrudsImageUpload
}