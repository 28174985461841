import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Dialog, Toast, DataGrid } from '@components/molecules';
import { useForm, Controller } from 'react-hook-form';
import { Spinner, Autocomplete, DatePicker } from '@components/atoms';
import { Helmet } from 'react-helmet';
import { Grid, Box, Button } from '@material-ui/core';
import API from '@services/api';
import { getPermissions, capitalizeFirstLetter } from '@services/utils';
import { useAuth } from '@context/auth';
import AddEdit from './addEdit';
import moment from 'moment';
import fileDownload from 'js-file-download';



const CrudPage = ({ children, ...props }) => {
    const [nonFieldErrorMessage, setNonFieldErrorMessage] = useState([]);
    const [objectList, setObjectList] = useState([]);
    const [object, setObject] = useState({});
    const [modal, setModal] = useState(false);
    const [dialog, setDialog] = useState('');
    const [toast, setToast] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({ prev: null, next: null });
    const { control, setError, handleSubmit, formState: { errors }, setValue, getValues, watch, clearErrors, reset, unregister, register } = useForm();
    const { control: controlSearch, handleSubmit: handleSubmit2, setValue: setSearchValue, getValues: getSearchValues } = useForm();
    const [has_add, setHas_add] = useState(false);
    const [has_change, setHas_change] = useState(false);
    const [has_delete, setHas_delete] = useState(false);
    const [inlineCounters, setInlineCounter] = useState({});
    const [inlinIndexes, setInlineIndexes] = React.useState({});
    const [tabDisabled, setTabDisabled] = React.useState({});
    const [tabValue, setTabValue] = React.useState(0);
    const [fieldWrapperVisible, setFieldFieldWrapperVisible] = React.useState({});
    const search = props.search || [];
    const { permissions } = useAuth();
    const inlines = props.inlines || [];
    const forceReload=props.forceReload || false;


    useEffect(() => {
        const app = props.app;
        const model = props.model;
        const perms = getPermissions(permissions, app, model);
        if (typeof (props.can_add) === 'undefined')
            setHas_add(perms.includes(`${app}.add_${model}`));
        else
            setHas_add(props.can_add);

        if (typeof (props.can_change) === 'undefined')
            setHas_change(perms.includes(`${app}.change_${model}`));
        else
            setHas_change(props.can_change);

        if (typeof (props.can_delete) === 'undefined')
            setHas_delete(perms.includes(`${app}.delete_${model}`));
        else
            setHas_delete(props.can_delete);

        async function getData() {
            try {
                let response = await props.api.list(null, null);
                setPagination({ prev: response.data.previous, next: response.data.next });
                setObjectList(response.data.results);
                setLoading(false);
                resetInlineCounter();
            } catch (error) {
                console.log(error);
            }
        }

        getData();

    }, []);

    useEffect(() => {
        async function getData() {
            if(forceReload){
                let response = await props.api.list(null, null);
                setPagination({ prev: response.data.previous, next: response.data.next });
                setObjectList(response.data.results);
                setLoading(false);
            }

        }

        getData();        
    
    }, [forceReload]);


    function getFields() {
        const add_fields = props.add_fields ? props.add_fields : props.fields;
        const fields = object == null ? add_fields : props.fields;
        return fields;
    }

    function resetInlineCounter() {
        const dCounter = {};
        const dIndex = {};
        inlines.forEach((inlineItem) => {
            dCounter[inlineItem.name] = 0;
            dIndex[inlineItem.name] = new Map();
        });
        setInlineCounter(dCounter);
        setInlineIndexes(dIndex);
    }


    const handlePagination = async (e, path) => {
        // controla paginacao
        e.preventDefault();
        try {
            setLoading(true);
            const response = await API.get(path);
            setObjectList(response.data.results);
            setPagination({ prev: response.data.previous, next: response.data.next });
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearchSubmit = async (data) => {
        // efetua  a busca
        setLoading(true);
        search.forEach(item => {
            if (item.type === 'autocomplete' && item.name in data) {
                if (data[item.name] != null) {
                    data[item.name] = data[item.name].id;
                }
            }
        })
        try {
            const response = await props.api.list(data, null);
            setObjectList(response.data.results);
            setPagination({ prev: response.data.previous, next: response.data.next });
        } catch (error) {
            setToast({
                severity: "error",
                msg: 'Filtro informado é inválido.'
            });
        }
        setLoading(false);
    }

    const exportar = async () => {
        try {
            setLoading(true);    
            const response = await props.api.export(getSearchValues());
            fileDownload(response.data, props.exportfilename);
            setLoading(false);
        } catch (error) {
            setToast({
                severity: "error",
                msg: 'Filtro informado é inválido.'
            });
            setLoading(false);
        }
    }    

    const clearFilter = async () => {
        // limpa botao filtrar
        setLoading(true);
        search.forEach((item) => {
            if (item.type === 'checkbox')
                setSearchValue(item.name, false);
            else if (item.type === 'autocomplete')
                setSearchValue(item.name, null);
            else
                setSearchValue(item.name, '');
        });

        const response = await props.api.list(null, null);
        setObjectList(response.data.results);
        setPagination({ prev: response.data.previous, next: response.data.next });
        setLoading(false);
    }

    const formatErrors = async (error) => {
        // formata erro vindos do django
        setLoading(false);
        if (error.response) {
            const data = error.response.data;
            if (data.non_field_errors) {
                setNonFieldErrorMessage(data.non_field_errors);
            } else {
                for (const [key, value] of Object.entries(data)) {
                    // console.log('key', key);
                    let isInline = false;
                    inlines.forEach(inline => {
                        if (inline.name === key) {
                            value.forEach(v => {
                                if (Object.keys(v).length > 0) {
                                    isInline = true;
                                    const inlineIndex = v['inlineIndex'];
                                    delete v['inlineIndex'];
                                    for (const [ki, vi] of Object.entries(v)) {
                                        setError(`${inline.name}_${inlineIndex}_${ki}`, {
                                            type: "manual",
                                            message: vi[0],
                                        });
                                    }
                                }
                            });

                        }
                    });
                    if (!isInline) {
                        setError(key, {
                            type: "manual",
                            message: value[0],
                        });
                    }

                }
            }
        } else {
            setNonFieldErrorMessage(JSON.stringify(error));
        }
    }

    const parseItemData = (item, data) => {
        if (item.type === 'autocomplete' && item.name in data) {
            if (data[item.name] != null) {
                if (item.multiple){
                    const values = data[item.name];
                    data[item.name] = [];
                    values.forEach((el) => {
                        data[item.name].push(el.id);
                    });

                }else{
                    data[item.name] = data[item.name].id;
                }
            }
        } else if ((item.type === 'iosswitch' || item.type === 'checkbox') && item.name in data && data[item.name] === "") {
            data[item.name] = false;
        } else if (((item.type === 'textbox' && item.inputType === 'number') || (item.type === 'datepicker')) && item.name in data && data[item.name] === "") {
            data[item.name] = null;
        }
    }

    const parseData = data => {
        // reorganiza inlines
        inlines.forEach((inlineItem) => {
            const map = new Map();
            for (const [key, value] of Object.entries(data)) {
                if (key.startsWith(inlineItem.name)) {
                    const match = key.match(/_([\d]+)_/);
                    const l = key.split(match[0]);
                    if (l.length === 2) {
                        const idx = parseInt(match[1]);
                        const name = l[1];
                        let dictionary = {};
                        if (map.has(idx))
                            dictionary = map.get(idx);

                        if (!(name === 'id' && value === '')) {
                            dictionary[name] = value
                            map.set(idx, dictionary);
                        }
                    }
                    delete data[key];
                }
            }
            // final tratamento inline
            data[inlineItem.name] = Array.from(map.values());
            data[inlineItem.name].forEach((itemData) => {
                inlineItem.fields.forEach(item => {
                    parseItemData(item, itemData)
                })
            });
        });
        getFields().forEach(item => {
            parseItemData(item, data)
        })
        return data;
    }
    const handleCreateSubmit = async (data) => {
        try {
            setLoading(true);
            data = parseData(data);
            await props.api.create(data);
            handleSubmit2(handleSearchSubmit)();
            setModal(false);
            setToast({
                severity: "success",
                msg: `${props.title} adicionado com sucesso!`
            });
        } catch (error) {
            formatErrors(error);
        }
    }

    const handleUpdateSubmit = async (data) => {
        try {
            setLoading(true);
            data = parseData(data);
            await props.api.update(object.id, data);
            handleSubmit2(handleSearchSubmit)();
            setModal(false);
            setToast({
                severity: "success",
                msg: `${props.title} atualizado com sucesso!`
            });
        } catch (error) {
            formatErrors(error);
        }
    }

    const handleDeleteSubmit = async () => {
        try {
            setLoading(true);
            let response = await props.api.delete(object.id);
            if (response.status === 204) {
                await handleSubmit2(handleSearchSubmit)();
                setDialog('');
                setObject(null);
                setToast({
                    severity: "error",
                    msg: `${props.title} excluído com sucesso!`
                });
            }
            setLoading(false);
        } catch (error) {
            setToast({
                severity: "error",
                msg: error
            });
        }
    }

    const onDelete = (params) => {
        setToast(null);
        setObject(params.row);
        setDialog(`Tem certeza que deseja apagar "${props.title}"? Caso exista dados relacionados, eles também serão apagados!`);
    }

    const onUpdate = (params) => {
        setFieldFieldWrapperVisible({});
        setObject(params.row);
        reset();
        resetInlineCounter();
        props.fields.forEach((item) => {
            setValue(item.name, params.row[item.name]);
            clearErrors(item.name);
        });

        setToast(null);
        setNonFieldErrorMessage([]);
        setModal(true);
        if (inlines.length > 0) {
            // inlines
            props.api.detail(params.row.id).then((response) => {
                const dCounter = {};
                const dIndex = {};
                inlines.forEach((inlineItem) => {
                    dCounter[inlineItem.name] = response.data[inlineItem.name].length;
                    dIndex[inlineItem.name] = new Map();
                    for (var i = 0; i < dCounter[inlineItem.name]; i++)
                        dIndex[inlineItem.name].set(i, i);
                    response.data[inlineItem.name].forEach((data, index) => {
                        inlineItem.fields.forEach((f) => {
                            const name = `${inlineItem.name}_${index}_${f.name}`;
                            setValue(name, data[f.name]);
                        });
                        const name = `${inlineItem.name}_${index}_id`;
                        setValue(name, data['id']);
                    });
                    if (inlineItem.onUpdateData) {
                        const usecruds = () => ({
                            'data': response.data[inlineItem.name],
                            'setFieldFieldWrapperVisible': setFieldFieldWrapperVisible
                        });
                        inlineItem.onUpdateData(usecruds);
                    }
                });
                setInlineCounter(dCounter);
                setInlineIndexes(dIndex);
            });
        }
    }

    const onAdd = () => {
        setFieldFieldWrapperVisible({});
        reset();
        resetInlineCounter();
        getFields().forEach((item) => {
            clearErrors(item.name);
            if (item.type === 'checkbox')
                setValue(item.name, false);
            else if (item.type === 'autocomplete')
                setValue(item.name, item.multiple?[]:null);
            else if (item.type === 'decimal') {
                setValue(item.name, item.defaultValue || "");
            }
            else {
                setValue(item.name, '');
            }
        });
        setObject(null);
        setToast(null);
        setModal(true);
        setNonFieldErrorMessage([]);
    }

    const filtros = () => {

        return (
            <form onSubmit={handleSubmit2(handleSearchSubmit)}>
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                >
                    {/* TODO -> TROCAR INPUTS */}
                    {search.map((item, idx) => {
                        switch (item.type) {
                            case 'textbox':
                                return (
                                    <Grid key={idx} item sm={item.sm || 3} xs={12}>
                                        <Box p={1}>
                                            <Controller
                                                name={item.name}
                                                control={controlSearch}
                                                defaultValue=""
                                                rules={{ required: item.required || false }}
                                                render={({ field }) => {
                                                    return (
                                                        <>
                                                            <TextField
                                                                fullWidth
                                                                // autoFocus                                                         
                                                                label={item.label || capitalizeFirstLetter(item.name)}
                                                                variant="outlined"
                                                                size="small"
                                                                {...field}
                                                            />
                                                        </>
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                )
                            case 'datepicker':
                                return (
                                    <Grid key={idx} item sm={item.sm || 3} xs={12}>
                                        <Box p={1}>
                                            <Controller
                                                name={item.name}
                                                control={controlSearch}
                                                // defaultValue=""
                                                rules={{ required: item.required || false }}
                                                render={({ field }) => {
                                                    return (
                                                        <>
                                                            <DatePicker
                                                                fullWidth
                                                                label={item.label || capitalizeFirstLetter(item.name)}
                                                                onChange={(date) => setSearchValue(item.name, moment(date).format('DD/MM/YYYY'))}
                                                                variant={'outlined'}
                                                                value={moment(getSearchValues(item.name), 'DD/MM/YYYY')}
                                                            />
                                                        </>
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                )
                            case 'autocomplete':
                                return (
                                    <Grid key={idx} item sm={item.sm || 3} xs={12}>
                                        <Box p={1}>
                                            <Controller
                                                name={item.name}
                                                control={controlSearch}
                                                defaultValue={null}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <>
                                                            <Autocomplete
                                                                label={item.label || capitalizeFirstLetter(item.name)}
                                                                api={item.api}
                                                                forward={item.forward}
                                                                optionLabel={(opt) => opt.label}
                                                                fullWidth
                                                                onChange={(e, v) => {
                                                                    onChange(v);
                                                                }}
                                                                value={value}
                                                            />
                                                        </>
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                )
                            default:
                                return (null)
                        }
                    })}

                    <Grid item xs={12} sm={4}>
                        <Button type="submit" color="primary" variant="contained">
                            Aplicar Filtro
                        </Button>
                        <Button color="default" variant="contained" style={{ marginLeft: '5px' }} onClick={clearFilter}>
                            Limpar Filtro
                        </Button>
                    </Grid>
                </Grid>
            </form>
        )
    }



    return (
        <>
            <Helmet>
                <title>{props.nome}</title>
            </Helmet>
            <Spinner open={loading} />

            <DataGrid
                filtros={search.length > 0 ? filtros : null}
                action={props.export?exportar:null}
                icon={props.pageIcon}
                subtitle={props.subtitle}
                title={props.title}
                rows={objectList}
                columns={props.listColumns}
                formfields={props.fields}
                onUpdate={onUpdate}
                onDelete={onDelete}
                onAdd={onAdd}
                loading={loading}
                handleNext={(e) => pagination.next && handlePagination(e, pagination.next)}
                handlePrev={(e) => pagination.prev && handlePagination(e, pagination.prev)}
                pagination={pagination}
                has_add={has_add}
                has_change={has_change}
                has_delete={has_delete}
                extra_buttons={props.extra_buttons || []}
            />
            {modal &&
                <AddEdit
                    setModal={setModal}
                    setObject={setObject}
                    object={object}
                    handleSubmit={handleSubmit}
                    handleUpdateSubmit={handleUpdateSubmit}
                    handleCreateSubmit={handleCreateSubmit}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    watch={watch}
                    nonFieldErrorMessage={nonFieldErrorMessage}
                    getValues={getValues}
                    unregister={unregister}
                    setError={setError}
                    register={register}
                    inlineCounters={inlineCounters}
                    setInlineCounter={setInlineCounter}
                    inlinIndexes={inlinIndexes}
                    setInlineIndexes={setInlineIndexes}
                    tabDisabled={tabDisabled}
                    setTabDisabled={setTabDisabled}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    fieldWrapperVisible={fieldWrapperVisible}
                    setFieldFieldWrapperVisible={setFieldFieldWrapperVisible}
                    getFields={getFields}
                    {...props}
                />

            }
            <Dialog
                open={!!dialog}
                title={dialog}
                handleClose={() => { setDialog(''); setObject(null) }}
                handleSubmit={handleDeleteSubmit}
            />
            {toast && (
                <Toast
                    open={!!toast}
                    severity={toast && toast.severity}
                    handleClose={() => { setToast(null); setObject(null) }}
                    duration={3000}
                >
                    {toast && toast.msg}
                </Toast>
            )}
            {children}
        </>
    )
}


export default CrudPage;



