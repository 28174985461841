import React, {useState} from 'react';
import API from '@services/pesquisapreco';
import ProdutoAPI from '@services/produto';
import ConcorrenteAPI from '@services/concorrente';
import AliquotaAPI from '@services/aliquota';
import EstadoAPI from '@services/estado';
import ImpostoAPI from '@services/imposto';
import { CrudPage } from '@components/organisms';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import UnidadeMedidaAPI from '@services/unidademedida';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function PesquisaPrecoPage() {
    const [labelPreco, setLabelPreco] = useState('Preço');
    const [labelImposto, setLabelImposto] = useState();
    return (
        <>
            <CrudPage
                api={API}
                title={'Preços'}
                subtitle={'Pesquisa'}
                app={'pesquisa_preco'}
                model={'pesquisapreco'}
                can_change={false}
                can_delete={false}
                listColumns={
                    [
                        {
                            field: 'id',
                            headerName: 'Número da Pesquisa',
                            flex: 1
                        },
                        {
                            field: 'data',
                            headerName: 'Data',
                            flex: 1
                        },                         
                        {
                            field: 'estado',
                            headerName: 'Estado',
                            flex: 1,                          
                        },  
                        {
                            field: 'concorrente',
                            headerName: 'Concorrente',
                            flex: 1,                          
                        },                                               
                    ]
                }
                fields={[
                    {
                        type:'autocomplete',
                        name:'concorrente', 
                        label: 'Concorrente',
                        required: true,
                        api: ConcorrenteAPI,
                    },
                    {
                        type:'autocomplete',
                        name:'impostos', 
                        label: 'Impostos',
                        required: true,
                        api: ImpostoAPI,
                        multiple:true,
                        renderOption:(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.label}
                            </React.Fragment>
                        ),
                        disableCloseOnSelect:true,
                        onChange:(v, usecruds) => {
                            const {getValues} = usecruds();
                            const impostos = getValues('impostos');  
                            if(impostos.length){
                                let text = impostos.map((el) => el.label).join(', ');
                                setLabelImposto(text);
                                setLabelPreco(`Preço com ICMS + "${text}":`);
                            }                          
                        }
                    }, 
                    {
                        type:'autocomplete',
                        name:'estado', 
                        label: 'Estado',
                        required: true,
                        api: EstadoAPI,
                        xs:6
                    },                     
                    {
                        type:'autocomplete',
                        name:'aliquota', 
                        label: 'Alíquota ICMS',
                        required: true,
                        api: AliquotaAPI,
                        xs:6
                    },                                       
                ]}
                search={[]}
                pageIcon={<FindReplaceIcon />}
                fullScreen={true}
                showtab={true}
                inlines={[
                    {
                        name: 'itens',
                        title: 'Produtos',
                        fields:
                            [ 
                                {
                                    type:'autocomplete',
                                    name:'produto', 
                                    label: 'Produto',
                                    required: true,
                                    api: ProdutoAPI,
                                    onChange:(value, usecruds) => {
                                        const {formset, inlineIndex, setValue} = usecruds();
                                        if(value){
                                            ProdutoAPI.detail(value.id).then((r) => {
                                                setValue(`${formset}_${inlineIndex}_unidade_medida`, r.data.unidade_medida);
                                            })
                                        }  
                                    }                                    
                                },
                                {
                                    type: 'autocomplete',
                                    name: 'unidade_medida',
                                    label: 'Un.',
                                    required: true,
                                    disabled: true,
                                    api: UnidadeMedidaAPI,
                                    xs:3
                                },                             
                                {
                                    type:'money',
                                    name:'preco', 
                                    label: labelPreco,
                                    required: true,
                                    xs:9
                                },                                                                                                                                
                            ]
                    }
                ]}

            />
        </>
    );
}