import React, { useState, useEffect } from 'react';
import { Box, Grid, Link, Button } from '@material-ui/core';
import { TextField, NonFieldErrorMessage, ErrorMessage, } from '@components/atoms';
import { AuthHeader } from '@components/molecules';
import { useForm } from 'react-hook-form';
import { useAuth } from '@context/auth';
import { Helmet } from 'react-helmet';
import UsuarioAPI from '@services/usuario';
import urls from '@urls';




export default function LoginPage({ history }) {
    const { handleSubmit, control, setError, formState: { errors } } = useForm();
    const [nonFieldErrorMessage, setNonFieldErrorMessage] = useState([]);
    const [userValidated, setUserValidated] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const { login, isAuthenticated } = useAuth();

    useEffect(()=>{
        if(isAuthenticated)
            window.location.href = '/';
    }, []);
    
    const onSubmit = async (data) => {
        setDisableButton(true)
        try {
            const response = await UsuarioAPI.login(data);
            const { user } = response.data;
            if (user) {
                setUserValidated(true)
            }
        } catch (error) {
            if (error.response.data.non_field_errors) {
                setNonFieldErrorMessage(error.response.data.non_field_errors);
            } else {
                for (const [key, value] of Object.entries(error.response.data)) {
                    setError(key, {
                        type: 'manual',
                        message: value,
                    });
                }
            }
            setDisableButton(false)
        }
    }

    async function verifyEmailCodeAndLogin(data) {
        try {
            const response = await UsuarioAPI.validateEmailCode({
                email: data.email,
                codigo: data.emailCode
            });
            const { token, user } = response.data;
            if (token && user) {
                login(token, user);
                history.push(urls.index);
            }

        } catch (error) {
            if (error.response.data.non_field_errors) {
                setNonFieldErrorMessage(error.response.data.non_field_errors);
            }
            setDisableButton(false)
            return
        }

    }

    function EmailComponent() {
        return <>
            <Helmet>
                <title>Acesso - Pesquisa Preço</title>
            </Helmet>
            <AuthHeader title="Código enviado por email" />
            <Box mt={4}>
                <form onSubmit={handleSubmit(verifyEmailCodeAndLogin)}>
                    <Box mt={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="emailCode"
                            label="Código"
                            name="emailCode"
                            autoComplete="emailCode"
                            autoFocus
                            control={control}
                        />
                    <NonFieldErrorMessage non_field_errors={nonFieldErrorMessage} />
                    </Box>
                    <Box mt={6}>
                        <Grid container spacing={6}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Validar código
                            </Button>
                        </Grid>
                    </Box>
                </form>
            </Box>
        </>
    }

    function LoginComponent() {
        return <>
            <Helmet>
                <title>Acesso - Pesquisa Preço</title>
            </Helmet>
            <AuthHeader title="Iniciar Sessão" />
            <Box mt={4}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        control={control}
                    />
                    <ErrorMessage error={errors['email']} />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="senha"
                        autoComplete="current-password"
                        control={control}
                    />
                    <ErrorMessage error={errors['password']} />
                    <Link href="#" onClick={(e) => { e.preventDefault(); history.push(urls.acesso.esqueci) }} variant="body2" color="secondary">
                        Esqueci minha senha
                    </Link>
                    <NonFieldErrorMessage non_field_errors={nonFieldErrorMessage} />
                    <Box mt={6}>
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <Button type="submit" disabled={disableButton} variant="contained" color="primary" fullWidth>
                                    Entrar
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button type="button" variant="outlined" color="primary" fullWidth onClick={() => history.push(urls.acesso.cadastro.index)}>
                                    Cadastre-se
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>
        </>
    }

    function ShowEmailComponent() {
        if (userValidated) {
            return <EmailComponent />
        }
        else {
            return <LoginComponent />
        }
    }

    return (
        <ShowEmailComponent />
    );
}

