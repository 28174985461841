import { include } from 'named-urls';


const urls = {
    index: '/',
    acesso: include('/acesso/', {
        login: 'login',
        cadastro: include('cadastro/', {
            index: '',
            finalizado: 'finalizado',
        }),
        esqueci: 'esqueci-minha-senha',
        alterarsenha:'alterarsenha',
        novasenha: 'nova-senha/:hash',
    }),
    impostos: include('/impostos/', {
        imposto: 'imposto',
        aliquota: 'aliquota',
    }),
    produtos: include('/produtos/', {
        produto: 'produto',
        listapreco: 'listapreco',
        unidademedida: 'unidade-medida'
    }),
    usuarios: include('/usuarios/', {
        usuario: 'usuario',
        pendentes: 'pendentes'
    }),
    entidades: include('/entidades/', {
        concorrente: 'concorrente'
    }),   
    pesquisapreco: include('/pesquisapreco/', {
        pesquisapreco: 'pesquisapreco',
        historico: 'historico',

    }),     
    unauthorized: '401',
}
export default urls;
