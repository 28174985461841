import { BaseAPIClass } from './api';


class ListaPrecoAPI extends BaseAPIClass{
    constructor() {
        super('/produtos/lista-preco/');
      }  
}


export default new ListaPrecoAPI();
