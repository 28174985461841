import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    errorMessageContainerStyle:{
        marginBottom: '20px',
        textAlign: 'center'
    },
    errorMessageText:{
        color: 'red'
    }
}));

const NonFieldErrorMessage = props => {
const classes = useStyles();
  let message = null;
  let non_field_errors = props.non_field_errors;
  if( typeof non_field_errors === 'string' ) {
    non_field_errors = [ non_field_errors ];
}

  if (non_field_errors) {
    message = (
      <div className={classes.errorMessageContainerStyle}>
        {non_field_errors.map(item => (
          <span className={classes.errorMessageText} key={item}>
            {item}
          </span>
        ))}
      </div>
    );
  }
  return message;
};

export default NonFieldErrorMessage;