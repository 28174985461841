import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import BrLocale from 'date-fns/locale/pt-BR';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';



export default function DatePicker({ ...props }) {
    const [date, setDate] = useState(null);

    useEffect(() => {
        setDate(props.value);

    }, [props.value]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={BrLocale}>
            <KeyboardDatePicker
                format="dd/MM/yyyy"
                id={props.id}
                label={props.label}
                value={date}
                onChange={(dt) => {
                    setDate(dt);
                    props.onChange(dt);
                }}
                className={props.className}
                inputVariant={props.variant || 'standard'}
                helperText={''}
                error={props.error}
                size={props.size || 'small'}
                fullWidth
                KeyboardButtonProps={{
                    "aria-label": "Alterar data"
                }}
                invalidDateMessage="Data inválida"
                onBlur={(ev, obj) => {
                    if (ev.target.value)
                        props.onChange(date);
                    else
                        props.onChange(null);
                    if (props.onBlur) {
                        props.onBlur(ev)
                    }
                }}
            />
        </MuiPickersUtilsProvider>
    )
}