import React from 'react';
import { Typography, Box } from '@material-ui/core';


export default function UnauthorizedPage() {
    return (
        <Box textAlign="center" my={12}>
            <Typography variant="h1" color="priamry">
                401
            </Typography>
            <Typography variant="h6" color="secondary">
                Unauthorized Access
            </Typography>
        </Box>
    );
}
