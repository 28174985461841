import React from 'react';


const ErrorMessage = props => {
  let message = null;
  if (props.error) {
    message = (
        <p style={{color:'red', fontStyle:'italic'}}>{props.error.message}</p>
    );
  }
  return message;
};

export default ErrorMessage;
