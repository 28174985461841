import React from 'react';
import MTextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';



export default function TextField(props) {
    const { name, label, control, ...others } = props;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <MTextField
                    {...others}
                    onChange={onChange}
                    value={value}
                    label={label}
                 />
            )}
        />

    );
};
