import React from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '@context/auth';
import { Sidebar } from '@components/molecules';
import CssBaseline from '@material-ui/core/CssBaseline';
import Bg from '@assets/bg/preco.png'


const useAuthStyles = makeStyles(() => ({
    bg:{
        backgroundColor: 'transparent',      
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: 'url(' + Bg + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',        
    },
}));


export default function MasterPage({ children }) {
    const { isAuthenticated } = useAuth();
    const authClasses = useAuthStyles();

    return (
        <>
            <CssBaseline />
            {isAuthenticated ? (
                <>
                    <Sidebar>
                        {children}
                    </Sidebar>
                </>
            ) : (
                <>
                    <Box className={authClasses.bg}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={10} lg={5}>
                                <Paper>
                                    <Box p={4}>
                                        {children}
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>

                </>
            )}
        </>
    )
}
