import React, { createRef, useEffect } from 'react';
import { Modal } from '@components/molecules';
import {
    NonFieldErrorMessage
} from '@components/atoms';
import { Grid } from "@material-ui/core";
import {
    CrudsEditTextBox,
    CrudsAutoComplete,
    CrudsCheckBox,
    CrudsIOSSwitch,
    CrudsMoney,
    CrudsDecimal,
    CrudsDateMask,
    CrudsMask,
    CrudsDatePicker,
    CrudsImageUpload
} from './inputs';
import CrudsInline from './inlines';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: '5px' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


const AddEdit = (props) => {
    const classes = useStyles();


    const handleTabChange = (event, newValue) => {
        props.setTabValue(newValue);
    };
    let fieldsRef = {};

    const fields = props.getFields();

    // TODO -> all inputs
    fields.forEach((item) => {
        if (item.type === 'textbox') {
            fieldsRef[item.name] = createRef();
        }
    });
    const inlines = props.inlines || [];

    useEffect(() => {
        inlines.forEach(inline => {
            const d = {}
            d[inline.name] = false;
            props.setTabDisabled(prev => ({ ...prev, d }));
        });
    }, []);

    return (
        <Modal
            handleClose={() => { props.setModal(false); props.setObject(null); }}
            title={props.object && props.object.id ? `Editar ${props.title}` : `Adicionar ${props.title}`}
            handleSubmit={(props.object && props.object.id) ? props.handleSubmit(props.handleUpdateSubmit) : props.handleSubmit(props.handleCreateSubmit)}
            modalMaxWidth={props.modalMaxWidth}
            fullScreen={props.fullScreen}
        >
            <Grid container alignItems="center">
                {fields.map((item, idx) => {
                    let component;
                    switch (item.type) {
                        case 'textbox':
                            component = <CrudsEditTextBox
                                item={item}
                                idx={idx}
                                fieldsRef={fieldsRef}
                                control={props.control}
                                errors={props.errors}
                                setValue={props.setValue}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                            />
                            break;
                        case 'autocomplete':
                            component = <CrudsAutoComplete
                                item={item}
                                idx={idx}
                                control={props.control}
                                errors={props.errors}
                                fields={fields}
                                setValue={props.setValue}
                                fieldsRef={fieldsRef}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                getValues={props.getValues}
                            />
                            break;
                        case 'checkbox':
                            component = <CrudsCheckBox
                                item={item}
                                idx={idx}
                                control={props.control}
                                watch={props.watch}
                                setValue={props.setValue}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                            />
                            break;
                        case 'iosswitch':
                            component = <CrudsIOSSwitch
                                item={item}
                                idx={idx}
                                control={props.control}
                                watch={props.watch}
                                setValue={props.setValue}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                            />
                            break;
                        case 'money':
                            component = <CrudsMoney
                                item={item}
                                idx={idx}
                                control={props.control}
                                errors={props.errors}
                                setValue={props.setValue}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                            />
                            break;
                        case 'decimal':
                            component = <CrudsDecimal
                                item={item}
                                idx={idx}
                                control={props.control}
                                errors={props.errors}
                                setValue={props.setValue}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                            />
                            break;
                        case 'datemask':
                            component = <CrudsDateMask
                                item={item}
                                idx={idx}
                                control={props.control}
                                errors={props.errors}
                                setValue={props.setValue}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                            />
                            break;
                        case 'datepicker':
                            component = <CrudsDatePicker
                                item={item}
                                idx={idx}
                                control={props.control}
                                errors={props.errors}
                                setValue={props.setValue}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                                watch={props.watch}
                            />
                            break;
                        case 'mask':
                            component = <CrudsMask
                                item={item}
                                idx={idx}
                                control={props.control}
                                errors={props.errors}
                                setValue={props.setValue}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                            />
                            break;
                        case 'imageupload':
                            component = <CrudsImageUpload
                                item={item}
                                idx={idx}
                                control={props.control}
                                errors={props.errors}
                                setValue={props.setValue}
                                setTabDisabled={props.setTabDisabled}
                                setTabValue={props.setTabValue}
                                fieldWrapperVisible={props.fieldWrapperVisible}
                                setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                            />
                            break;                           
                        default:
                            component = <span>Invalid</span>;
                    }
                    return component;
                })
                }
            </Grid>
            {inlines.length > 0 &&
                <div className={classes.root}>
                    {props.showtab &&
                        <AppBar position="static">
                            <Tabs value={props.tabValue} onChange={handleTabChange}>
                                {inlines.map((inline, inlineOrder) =>
                                    <Tab disabled={props.tabDisabled[inline.name]} label={inline.title} {...a11yProps(inlineOrder)} />
                                )}
                            </Tabs>
                        </AppBar> 
                    }
                    {inlines.map((inline, inlineOrder) => {
                        const comp = <CrudsInline
                        inlineOrder={inlineOrder}
                        inline={inline}
                        control={props.control}
                        errors={props.errors}
                        setValue={props.setValue}
                        watch={props.watch}
                        unregister={props.unregister}
                        register={props.register}
                        inlineCounters={props.inlineCounters}
                        setInlineCounter={props.setInlineCounter}
                        inlinIndexes={props.inlinIndexes}
                        setInlineIndexes={props.setInlineIndexes}
                        fieldWrapperVisible={props.fieldWrapperVisible}
                        setFieldFieldWrapperVisible={props.setFieldFieldWrapperVisible}
                        getValues={props.getValues}
                        />;
                        if(props.showtab){
                            return(
                                <TabPanel value={props.tabValue} index={inlineOrder}>
                                    {comp}
                                </TabPanel>                                
                            )
                        };
                        return comp;
                    })}
                </div>

            }
            <NonFieldErrorMessage non_field_errors={props.nonFieldErrorMessage} />
        </Modal>
    )
}

export default AddEdit;