import api, { BaseAPIClass } from './api';


class ItensAPI extends BaseAPIClass{
    constructor() {
        super('/pesquisa_preco/itens/');
      }
      
    export = async(data) => {
      return api.get(this.path +'export/', {params:data, responseType: 'blob'}, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }});              
    }
}


export default new ItensAPI();
