import React from 'react';
import { Typography, Box } from '@material-ui/core';


export default function NotFoundPage() {
    return (
        <Box textAlign="center" my={12}>
            <Typography variant="h1" color="priamry">
                404
            </Typography>
            <Typography variant="h6" color="secondary">
                Not Found :(
            </Typography>
        </Box>
    );
}