import React, { useState } from 'react';
import ProdutoAPI from '@services/produto';
import UnidadeMedidaAPI from '@services/unidademedida';
import { CrudPage } from '@components/organisms';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';
import { Spinner } from '@components/atoms';
import Button from '@material-ui/core/Button';
import { hasPermission } from '@services/utils';
import { useAuth } from '@context/auth';
import { Input, InputLabel } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} color="blue">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        flex: 1,
        justifyContent: "space-between"
    },
}))(MuiDialogActions);
  
const Modal = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const handleCapture = ({ target }) => {
        setSelectedFile(target.files[0]);                
    };

    const onSubmit = () => {
        props.setImportLoading(true);
        ProdutoAPI.upload(selectedFile).then(() => {
            props.setImportModal(false);
            props.setImportLoading(false);
            props.setForceReload(true);
            setSelectedFile(null);
            setTimeout(function(){
                props.setForceReload(false);
            },1000);
        }).catch((error)=>{
            alert(error.response.data.non_field_errors);
            props.setImportLoading(false);
        });
    };

    return (
        <>
            <Dialog
                aria-labelledby="import-dialog-title"
                fullWidth
                open={props.open}
                maxWidth={"sm"}
            >
                <DialogTitle id="import-dialog-title" onClose={props.onClose}>
                    Importar Produto
                </DialogTitle>
                <DialogContent dividers>

                    <InputLabel htmlFor="import-button" style={{ color: "black" }}>
                        <Input
                            id="import-button"
                            inputProps={{
                                accept:
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                            }}
                            onChange={handleCapture}
                            style={{ display: "none" }}
                            type="file"
                        />
                        <Button color="secondary" variant="contained" component="span">
                            {selectedFile ? selectedFile.name : "Selecionar Arquivo"}
                        </Button>
                        <br /><br />
                        (<a href={process.env.PUBLIC_URL + '/exemplo-xls-produto.xlsx'}>template</a>)
                    </InputLabel>
                    
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}
                    style={{display: selectedFile == null?"none":"block"}}
                    >
                        Importar Produto
                    </Button>
                </DialogActions>
            </Dialog>            
        </>
    )
}

export default function ProdutoPage() {
    const [importModal, setImportModal] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const { permissions } = useAuth();
    const [forceReload, setForceReload] = useState(false);

    const extraButton = () => {
        const lista = [];

        if (hasPermission(permissions, 'pode_importar_produto')) {
            lista.push(
                <Button variant="contained" onClick={(e) => {
                    e.preventDefault();
                    setImportModal(true);
                }} color="primary" style={{ marginLeft: '10px' }}>
                    Importar XLS
                </Button>
            )
        }

        return lista;
    }

    return (
        <>
            <Spinner open={importLoading} />
            <CrudPage
                api={ProdutoAPI}
                title={'Produtos'}
                subtitle={'Produtos'}
                app={'produtos'}
                model={'produto'}
                forceReload={forceReload}
                listColumns={
                    [
                        {
                            field: 'codigo_prysmian',
                            headerName: 'Código Prysmian',
                            flex: 1
                        },
                        {
                            field: 'descricao',
                            headerName: 'Descrição',
                            flex: 1
                        },
                        {
                            field: 'unidade_medida',
                            headerName: 'Unidade Medida',
                            flex: 1
                        },
                        {
                            field: 'preco',
                            headerName: 'Preço',
                            flex: 1,
                            renderCell:(params) => {
                                return params.value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(params.value) : ''
                            }                                                       
                        },
                        {
                            field: 'ipi',
                            headerName: 'IPI',
                            flex: 1
                        },
                        {
                            field: 'pis_cofins',
                            headerName: 'PIS/COFINS',
                            flex: 1
                        },                                                
                                              
                    ]
                }
                fields={
                    [                      
                        {
                            type: 'textbox',
                            name: 'codigo_prysmian',
                            label: 'Código Prysmian',
                            required: true
                        },
                        {
                            type: 'textbox',
                            name: 'descricao',
                            label: 'Descrição',
                            required: true
                        },
                        {
                            type: 'autocomplete',
                            name: 'unidade_medida',
                            label: 'Unidade Medida',
                            required: true,
                            api: UnidadeMedidaAPI
                        },    
                        {
                            type: 'decimal',
                            name: 'ipi',
                            label: 'IPI',
                            required: true
                        },
                        {
                            type: 'decimal',
                            name: 'pis_cofins',
                            label: 'PIS/COFINS',
                            required: true
                        },                                                                    
                    ]
                }
                search={
                    [
                        {
                            type: 'textbox',
                            name: 'search',
                            label: 'Pesquise'
                        }
                    ]
                }
                pageIcon={<SettingsInputHdmiIcon />}
                extra_buttons={extraButton()}

            >
                <Modal open={importModal} 
                    setImportModal={setImportModal} 
                    setImportLoading={setImportLoading}
                    setForceReload={setForceReload} onClose={() => setImportModal(false)} />
            </CrudPage>
        </>
    );
}