import React from 'react';
import ImpostoAPI from '@services/imposto';
import { CrudPage } from '@components/organisms';
import GavelIcon from '@material-ui/icons/Gavel';



export default function ImpostoPage() {
    return (
        <>
            <CrudPage
                api={ImpostoAPI}
                title={'Impostos'}
                subtitle={'Impostos'}
                app={'impostos'}
                model={'imposto'}
                listColumns={
                    [
                        {
                            field: 'nome',
                            headerName: 'Nome',
                            flex: 1
                        },
                    ]
                }
                fields={
                    [
                        {
                            type: 'textbox',
                            name: 'nome',
                            required: true
                        },
                    ]
                }
                search={
                    [
                        {
                            type:'textbox',
                            name: 'search', 
                            label:'Pesquise'
                        }
                    ]
    
                }
                pageIcon={<GavelIcon />}
            />
        </>
    );
}