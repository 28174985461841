import api, {BaseAPIClass} from './api';



class UsuarioPendenteAPI extends BaseAPIClass{
    constructor() {
        super('/contas/usuario-pendente/');
    } 

    deny = async(data) => { 
        return await api.patch(`${this.path}deny/`, data);
    }   
    
    aprove = async(data) => { 
        return await api.patch(`${this.path}aprove/`, data);
    }      
}


export default new UsuarioPendenteAPI();
